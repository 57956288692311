import React from "react";

import ControlsBar from "../../../ControlsBar/ControlsBar";
import { Selections } from "../../../../../types";
import IndividualUserSelection from "../../../../IndividualUserPage/IndividualUserSelection/IndividualUserSelection";

import styles from "./RejectedItemsTab.module.scss"; // Update the import for Rejected items' styles

interface RejectedItemsTabProps {
  rejectedItems: Selections;
  handlePreviewClick: (url: string) => void;
  handleRejectedSelectAllCheckBoxClick: (
    e: React.ChangeEvent<HTMLInputElement>
  ) => void;
  handleCheckBoxClick: (
    e: React.ChangeEvent<HTMLInputElement>,
    id: number,
    index: number,

    status: "approved" | "rejected" | "unReviewed"
  ) => void;
  handleKeyDown: (
    e: React.KeyboardEvent<HTMLInputElement>,
    id: number,
    status: "approved" | "rejected" | "unReviewed"
  ) => void;
  handleAcceptanceClick: (
    type: "unReviewed" | "approved" | "rejected"
  ) => Promise<void>;
  handleRejectionClick: (
    type: "unReviewed" | "approved" | "rejected"
  ) => Promise<void>;
}

const RejectedItemsTab: React.FC<RejectedItemsTabProps> = ({
  rejectedItems,
  handlePreviewClick,
  handleRejectedSelectAllCheckBoxClick,
  handleCheckBoxClick,
  handleKeyDown,
  handleAcceptanceClick,
  handleRejectionClick,
}) => {
  return (
    <div className={styles.tabContentWrapper}>
      <h1 className={styles.tabHeader}>Rejected Items</h1>

      <ControlsBar
        message={`${rejectedItems.length} rejected items`}
        numberOfSelectedItems={
          rejectedItems.filter(({ is_checked }) => is_checked).length
        }
        handleRejectedSelectAllCheckBoxClick={
          handleRejectedSelectAllCheckBoxClick
        }
        onApprove={() => handleAcceptanceClick("rejected")}
        onReject={() => handleRejectionClick("rejected")}
      />
      <div className={styles.imagesHolder}>
        {rejectedItems.map((item, idx) => (
          <div key={item.id} className={styles.imageWrapper}>
            <IndividualUserSelection
              index={idx}
              currentUserSelection={item}
              handlePreviewClick={handlePreviewClick}
              handleCheckBoxClick={handleCheckBoxClick}
              handleKeyDown={handleKeyDown}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default RejectedItemsTab;
