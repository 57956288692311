import React from "react";
import { Users } from "../../../types";
import { Table } from "@spawning-inc/kiplu";
import styles from "./UsersTable.module.scss";
import { TableSortLabel } from "@mui/material";
import cn from "classnames";
import { useNavigate } from "react-router-dom";
import { NoteAdd, NoteAlt } from "@mui/icons-material";
import Button from "@mui/material/Button";
import NotesModal from "../../../components/NotesModal/NotesModal";
import { useState } from "react";
import axios from "axios";
import { END_POINT } from "../../../consts";

interface UsersTableProps {
  users: Users;
  orderValue: "pending_review_optouts" | "is_verified" | "id";
  sortOrderDir: "asc" | "desc";
  handleSortRequest: (
    name: "pending_review_optouts" | "is_verified" | "id"
  ) => void;
}

const UsersTable: React.FC<UsersTableProps> = ({
  users,
  orderValue,
  sortOrderDir,
  handleSortRequest,
}) => {
  const navigate = useNavigate();
  const [selectedNotes, setSelectedNotes] = useState({ id: 0, notes: "" });
  const [notesOpen, setNotesOpen] = useState(false);

  const handleSortClick =
    (name: "pending_review_optouts" | "is_verified" | "id") => () => {
      handleSortRequest(name);
    };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLTableRowElement>,
    id: number
  ) => {
    if (e.key !== "Enter") return;

    navigate(`/users/${id}`);
  };

  const openNotes = (id: number, notes: string) => {
    setSelectedNotes({ id, notes });
    setNotesOpen(true);
  };

  const saveNotes = async (id: number, notes: string) => {
    await axios.put(
      `${END_POINT}/users/${id}`,
      {
        notes: notes,
      },
      {
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }
    );
    setNotesOpen(false);
    setSelectedNotes({ id: 0, notes: "" });

    // update user
    const user = users.find((user) => user.id === id);
    if (user) {
      user.notes = notes;
    }
  };

  const openUserPage = (
    e: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    id: number
  ) => {
    if (e.target) {
      const target = e.target as HTMLElement;
      if (target.classList.contains("dontopen")) {
        return;
      }
    }
    // rewrite above line in typescript
    window.open(`/users/${id}`, "_blank", "noopener noreferrer");
  };

  return (
    <>
      <Table className={styles.table}>
        <Table.Head>
          <Table.Row>
            <Table.Cell key="id">
              <TableSortLabel
                active={orderValue === "id"}
                direction={orderValue === "id" ? sortOrderDir : "asc"}
                onClick={handleSortClick("id")}
              >
                ID
              </TableSortLabel>
            </Table.Cell>
            <Table.Cell key="email">Email </Table.Cell>
            <Table.Cell key="pending_review_optouts">
              <TableSortLabel
                className={styles.sortLable}
                active={orderValue === "pending_review_optouts"}
                direction={
                  orderValue === "pending_review_optouts" ? sortOrderDir : "asc"
                }
                onClick={handleSortClick("pending_review_optouts")}
              >
                Number of Pending Optouts
              </TableSortLabel>
            </Table.Cell>

            <Table.Cell key="notes">Notes</Table.Cell>
          </Table.Row>
        </Table.Head>

        <Table.Body>
          {users.map(
            ({ id, email, pending_review_optouts, is_verified, notes }) => (
              <Table.Row
                key={id}
                tabIndex={0}
                className={cn(styles.userRow)}
                onKeyDown={(e) => handleKeyDown(e, id)}
                onClick={(e) => openUserPage(e, id)}
              >
                <Table.Cell>{id}</Table.Cell>
                <Table.Cell>{email}</Table.Cell>
                <Table.Cell>{pending_review_optouts || 0}</Table.Cell>

                <Table.Cell>
                  <Button
                    className="dontopen"
                    onClick={(e) => {
                      e.stopPropagation();
                      openNotes(id, notes);
                    }}
                    variant="contained"
                    color={notes ? "info" : "success"}
                    endIcon={notes ? <NoteAlt /> : <NoteAdd />}
                  >
                    {notes ? "Edit" : "Add"}
                  </Button>
                </Table.Cell>
              </Table.Row>
            )
          )}
        </Table.Body>
      </Table>
      <NotesModal
        isOpen={notesOpen}
        setIsOpen={setNotesOpen}
        notes={selectedNotes.notes}
        onSave={(newNotesText) => {
          saveNotes(selectedNotes.id, newNotesText);
        }}
      />
    </>
  );
};

export default UsersTable;
