import React from "react";
import cn from "classnames";
import styles from "./Lozenge.module.scss";

interface LozengeProps {
  status: "positive" | "negative";
  type: "reviewed" | "verified" | "processed" | "trusted" | "rejected";
}

const Lozenge: React.FC<LozengeProps> = ({ status, type }) => {
  if (type === "rejected") {
    return (
      <div
        className={cn(
          styles.lozenge,
          { [styles.positive]: status === "positive" },
          { [styles.negative]: status === "negative" }
        )}
      >
        {status === "positive" ? "approved" : type}
      </div>
    );
  }

  return (
    <div
      className={cn(
        styles.lozenge,
        { [styles.positive]: status === "positive" },
        { [styles.negative]: status === "negative" }
      )}
    >
      <span className={styles.not}>{status === "negative" ? "NOT" : ""}</span>{" "}
      {type}
    </div>
  );
};
export default Lozenge;
