import React, { useEffect, useState } from "react";
import styles from "./URLReviewPage.module.scss";
import Layout from "../../components/Layout";
import { Select, TextField } from "@spawning-inc/kiplu";
import { Domains } from "../../types";
import axios from "axios";
import { END_POINT } from "../../consts";
import { Button, CircularProgress, Pagination } from "@mui/material";
import {
  getDomainComparator,
  sortedDomainsInformation,
} from "../../helpers/domainSorting";
import ErrorAlert from "../../components/ErrorAlert";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


const URLReviewPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [results, setResults] = useState([]);

  const getItems = async (
  ) => {
    setIsLoading(true);
    try {
      let url = `${END_POINT}/nsfw/review/`;
      const data = await axios.get(url, {
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      });

      setResults(data.data);

      setIsLoading(false);
      setIsError(false);
    } catch (error) {
      setIsLoading(false);

      setIsError(true);
      setErrorMessage("Failed to get list of NSFW items");
    }
  };

  useEffect(() => {
    getItems();
  }, []);

  const handleCopyURL = (url: string) => {
    navigator.clipboard.writeText(url);
  }

  const handleDelete = async (file_key: string) => {
    try {
      let url = `${END_POINT}/nsfw/review/`;
      const data = await axios.delete(url, {
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
          "Content-Type": "application/json"
        },
        data: JSON.stringify({key: file_key})
      });

      // remove from local data
      let newResults = results.filter((result: any) => result.key !== file_key);
      setResults(newResults);
    } catch (error) {
      setIsError(true);
      setErrorMessage("Failed to delete item");
    }
  }


  return (
    <Layout height="fit-content">
      <div className={styles.wrapper}>
        <h1>NSFW Review Items</h1>

        {isLoading ? (
          <CircularProgress />
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Copy URL</TableCell>
                  <TableCell>Caption</TableCell>
                  <TableCell>Search Term</TableCell>
                  <TableCell>From</TableCell>
                  <TableCell>Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {results.map((result: any) => (
                  <TableRow>
                    <TableCell><Button onClick={() => {handleCopyURL(result.url)}}>Copy URL</Button></TableCell>
                    <TableCell>{result.caption}</TableCell>
                    <TableCell>{JSON.stringify(result.search)}</TableCell>
                    <TableCell>{result.ip}</TableCell>
                    <TableCell sx={{maxWidth: "200px", lineBreak: "anywhere"}}><Button onClick={async ()=> { await handleDelete(result.key)}}>Delete</Button></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          
        )}

        <ErrorAlert
          isError={isError}
          setIsError={setIsError}
          errorMessage={errorMessage}
        />
      </div>
    </Layout>
  );
};

export default URLReviewPage;
