import axios from "axios";
import { Routes, Route } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Home from "./pages/Home/Home.tsx";
import UsersPage from "./pages/UsersPage/UsersPage";
import DomainsPage from "./pages/DomainsPage/DomainsPage";
import IndividualUserPage from "./pages/IndividualUserPage/IndividualUserPage";
import KudurruPage from "./pages/KudurruPage/KudurruPage";
import URLReviewPage from "./pages/URLReviewPage/URLReviewPage";
import KudurruIpLookupPage from "./pages/KudurruIpLookupPage/KudurruIpLookupPage";
import KudurruDomains from "./pages/KudurruDomains/KudurruDomains";
import KudurruWords from "./pages/KudurruWords/KudurruWords";
import Books3Page from "./pages/Books3Page/Books3Page";

import { Box } from "@mui/material";
import { AUTH_CLIENT_SECRET, AUTH_REDIRECT_URI } from "./consts";
import styles from "./App.module.scss";
import { Button, Modal } from "@spawning-inc/kiplu";
import UserItemsPage from "./pages/UserItemsPage/UserItemsPage";

const isProd = process.env.NODE_ENV !== "development" || !process.env.NODE_ENV;
// use withCredentials everywhere
axios.defaults.withCredentials = isProd;
const authDomain =
  "https://spawning-reporting-users.auth.us-east-2.amazoncognito.com";
const clientId = "3oste7j32t7iksp4p4cndrs9ap";
const loginPath =
  "/login?client_id=" +
  clientId +
  "&response_type=code&scope=email+openid+profile";
const callbackURL = AUTH_REDIRECT_URI;
const loginCodeExchangePath = "/oauth2/token";
const clientSecret = AUTH_CLIENT_SECRET;

function App() {
  const [isAuth, setIsAuth] = useState(false);

  // check if login page is accessible, and if not, prompt
  useEffect(() => {
    // check if we're logging in
    const url = new URL(window.location.href);
    const path = url.pathname;
    const code = url.searchParams.get("code");
    if (!isAuth && path === "/auth" && code) {
      // we're logging in, so send the code to the backend
      const handleLogin = async () => {
        try {
          const params = new URLSearchParams();
          params.append("code", code);
          params.append("grant_type", "authorization_code");
          params.append("client_id", clientId);
          params.append("redirect_uri", callbackURL);
          params.append("client_secret", clientSecret);

          const res = await axios.post(
            authDomain + loginCodeExchangePath,
            params
          );
          if (res.status !== 200) {
            console.error(res.data);
            setIsAuth(false);
          } else {
            // set the token in local storage
            localStorage.setItem("token", res.data.id_token);
            setIsAuth(true);
          }
        } catch (error) {
          console.error(error);
          setIsAuth(false);
        }
      };

      handleLogin();
      return;
    } else {
      // check if we're already logged in
      const token = localStorage.getItem("token");
      if (token) {
        // decode the JWT token and look at expiration time
        const tokenParts = token.split(".");
        const tokenPayload = JSON.parse(atob(tokenParts[1]));
        const tokenExpiration = tokenPayload.exp;
        const now = Math.floor(Date.now() / 1000);
        if (tokenExpiration < now) {
          // token is expired
          localStorage.removeItem("token");
          setIsAuth(false);
          return;
        } else {
          // set timeout for when token expires
          const timeout = tokenExpiration - now;
          setTimeout(() => {
            localStorage.removeItem("token");
            setIsAuth(false);
          }, timeout * 1000);

          // we're authed
          setIsAuth(true);
          return;
        }
      } else {
        setIsAuth(false);
        return;
      }
    }
  }, []);

  return (
    <>
      <Modal isOpen={!isAuth}>
        <Box className={styles.modalWrapper}>
          <Box>
            <h1>Log in to access the admin panel</h1>
          </Box>
          <Button
            variant="secondary"
            onClick={() => {
              window.open(
                authDomain +
                  loginPath +
                  "&redirect_uri=" +
                  encodeURIComponent(callbackURL),
                "_self"
              );
            }}
          >
            Login
          </Button>
        </Box>
      </Modal>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/auth" element={<Home />} />
        <Route path="/users" element={<UsersPage />} />
        <Route path="/users/:id" element={<UserItemsPage />} />
        {/* <Route path="/users/:id" element={<IndividualUserPage />} /> */}
        <Route path="/domains" element={<DomainsPage />} />
        <Route path="/books3" element={<Books3Page />} />
        */
        <Route path="/kudurru" element={<KudurruPage />} />
        <Route path="/kudurrudomains" element={<KudurruDomains />} />
        <Route path="/kudurruwords" element={<KudurruWords />} />
        <Route path="/kudurru/:id_address" element={<KudurruIpLookupPage />} />
        <Route path="/nsfw/review/" element={<URLReviewPage />} />
      </Routes>
    </>
  );
}

export default App;
