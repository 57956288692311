import { useParams } from "react-router-dom";
import Layout from "../../components/Layout";
import { useEffect, useState } from "react";
import axios from "axios";
import { Pie, Bar } from "react-chartjs-2";
import { Map, TileLayer, Marker, Popup } from "react-leaflet";
import { Icon } from "leaflet";
import { DomainColorList } from "../../components/DomainColorList/DomainColorList";

import chroma from "chroma-js";

import {
  ArcElement,
  CategoryScale,
  LinearScale,
  Chart,
  BarController,
  PieController,
  Title,
  Tooltip,
  BarElement,
} from "chart.js";
import { WidthFull } from "@mui/icons-material";

Chart.register(
  PieController,
  BarController,
  ArcElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  BarElement
);

type Request = {
  domain: string;
  ip: string;
  ip_information: string;
  timestamp: string;
  url: string;
  user_agent: string;
};

type Data = {
  distinct_domains: string[];
  domain_counts: Record<string, number>;
  ip: string;
  ip_information: string;
  number_of_requests: number;
  requests: Request[];
};

const clientIcon = new Icon({
  iconUrl: require("../../assets/marker-red.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

function usePagination(data: any, itemsPerPage: number) {
  const [currentPage, setCurrentPage] = useState(0);

  function currentData() {
    return data.slice(
      currentPage * itemsPerPage,
      (currentPage + 1) * itemsPerPage
    );
  }

  function nextPage() {
    setCurrentPage((oldPage) => Math.min(oldPage + 1, data.length - 1));
  }

  function prevPage() {
    setCurrentPage((oldPage) => Math.max(oldPage - 1, 0));
  }

  return { next: nextPage, prev: prevPage, currentData, currentPage };
}

function KudurruIpLookupPage() {
  let { id_address } = useParams<{ id_address: string }>();
  const [data, setData] = useState<Data | null>(null);
  const [ipData, setIpData] = useState<any | null>(null);
  const [isLoading, setIsLoading] = useState(true); // Added a new state to manage loading status
  const itemsPerPage = 10; // Change this number to change the number of items per page
  const pagination = usePagination(data?.requests || [], itemsPerPage);
  const [displayedRequests, setDisplayedRequests] = useState(0);
  const [countdown, setCountdown] = useState(60); // 60 seconds countdown

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true); // Set loading status to true
        const response = await axios.get(
          `https://disneycarscase.info/requests/${id_address}`,
          { withCredentials: false } // add this line
        );
        setData(response.data);
        setIpData(response.data.ip_information);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false); // Set loading status to false once the fetch is completed
      }
    };

    fetchData();

    const interval = setInterval(fetchData, 60000); // Fetch data every 1 minute

    const countdownInterval = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown === 0) {
          return 60; // reset countdown when it reaches 0
        }
        return prevCountdown - 1;
      });
    }, 1000); // Decrease countdown every 1 second

    return () => {
      setData(null); // Cleanup function to reset data
      clearInterval(interval); // Clear the interval when the component unmounts
      clearInterval(countdownInterval); // Clear the countdown interval
    };
  }, [id_address]);

  useEffect(() => {
    if (data) {
      const increment = Math.ceil(data.number_of_requests / 100);
      const interval = setInterval(() => {
        setDisplayedRequests((prev) => {
          if (prev + increment >= data.number_of_requests) {
            clearInterval(interval);
            return data.number_of_requests;
          }
          return prev + increment;
        });
      }, 20);
    }
  }, [data]);

  // chroma color scale
  const colorScale = chroma.scale(["#ff5000", "#9faaff"]);

  const chartData = {
    labels: Object.keys(data?.domain_counts || {}),
    datasets: [
      {
        label: "# of Hits",
        data: Object.values(data?.domain_counts || {}),
        // Map each domain to a color on the gradient.
        backgroundColor: Object.keys(data?.domain_counts || {}).map((_, i) =>
          colorScale(i / Object.keys(data?.domain_counts || {}).length).hex()
        ),
      },
    ],
  };

  const dateDataEntries = Object.entries(
    data?.requests.reduce((acc: Record<string, number>, req) => {
      const date = new Date(req.timestamp).toISOString().split("T")[0]; // Extract the date part
      acc[date] = (acc[date] || 0) + 1; // Increment the counter for this date
      return acc;
    }, {}) || {}
  );

  const dateChartData = {
    labels: dateDataEntries.map(([date]) => date),
    datasets: [
      {
        label: "# of Hits",
        data: dateDataEntries.map(([_, count]) => count),
        backgroundColor: "rgba(0, 123, 255, 0.5)",
        borderColor: "rgba(0, 123, 255, 1)",
        borderWidth: 1,
      },
    ],
  };

  function parseIpData(ipData: string | null) {
    if (!ipData) return null;

    const parts = ipData.split(", ");

    if (parts.length < 5) return null;

    const latLonParts1 = parts[4].split(": ");
    const latLonParts2 = parts[5].split(": ");

    return {
      ip: id_address,
      country: parts[0].trim(),
      state: parts[1].trim(),
      city: parts[2].trim(),
      organization: parts[3].trim(),
      latitude: latLonParts1.length === 2 ? parseFloat(latLonParts1[1]) : null,
      longitude: latLonParts2.length === 2 ? parseFloat(latLonParts2[1]) : null,
    };
  }

  const parsedData = parseIpData(ipData);

  return (
    <Layout height="fit-content">
      {parsedData && parsedData.latitude && parsedData.longitude ? (
        <div style={{ height: "500px", width: "100%", pointerEvents: "none" }}>
          <Map
            center={[parsedData.latitude, parsedData.longitude]}
            zoom={13}
            style={{ height: "100%", width: "100%" }}
            draggable={false}
            zoomControl={false}
            interactive={false}
          >
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <Marker
              position={[parsedData.latitude, parsedData.longitude]}
              icon={clientIcon}
            >
              <Popup>{parsedData.organization}</Popup>
            </Marker>
          </Map>
        </div>
      ) : null}
      {data ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "1rem 0",
            }}
          >
            {parsedData ? (
              <div
                style={{
                  flex: 1,
                  margin: "1rem",
                  padding: "1rem",
                  border: "1px solid #ccc",
                  borderRadius: "0.5rem",
                }}
              >
                <h2>{displayedRequests}</h2>

                <h2>{parsedData.ip}</h2>
                <p>
                  <strong>Country:</strong> {parsedData.country}
                </p>
                <p>
                  <strong>State:</strong> {parsedData.state}
                </p>
                <p>
                  <strong>City:</strong> {parsedData.city}
                </p>
                <p>
                  <strong>Organization:</strong> {parsedData.organization}
                </p>
                <p>Refresh in: {countdown}</p>
              </div>
            ) : (
              <p>Loading IP information...</p>
            )}
            <div style={{ flex: 1, margin: "1rem" }}>
              <Bar data={dateChartData} options={{ responsive: true }} />
            </div>
            <div style={{ flex: 1, margin: "1rem" }}>
              <Pie data={chartData} options={{ responsive: true }} />
            </div>
            <div style={{ flex: 1, margin: "1rem" }}>
              <DomainColorList chartData={chartData} />
            </div>
          </div>

          <div style={{ margin: "1rem", width: "60%" }}>
            {data && (
              <div
                style={{
                  position: "fixed",
                  top: "40px",
                  right: "40px",
                  fontSize: "180px",
                  zIndex: 9999,
                }}
              >
                {data.requests.some((request) =>
                  request.user_agent.includes(
                    "https://github.com/rom1504/img2dataset"
                  )
                )
                  ? "💩"
                  : data.requests.every(
                      (request) =>
                        request.user_agent ===
                        "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:72.0) Gecko/20100101 Firefox/72.0"
                    )
                  ? "☠"
                  : data.distinct_domains.length > 5
                  ? "?"
                  : "✓"}
              </div>
            )}
            <table style={{ tableLayout: "fixed", wordWrap: "break-word" }}>
              <thead>
                <tr>
                  <th>Domain</th>
                  {/* <th>IP</th> */}
                  <th>Timestamp</th>
                  <th>URL</th>
                  <th>User Agent</th>
                </tr>
              </thead>
              <tbody>
                {pagination
                  .currentData()
                  .map((request: Request, index: number) => (
                    <tr key={index}>
                      <td>{request.domain}</td>
                      {/* <td>{request.ip}</td> */}
                      <td>{request.timestamp}</td>
                      <td style={{ maxWidth: "200px", wordWrap: "break-word" }}>
                        {request.url}
                      </td>
                      <td>{request.user_agent}</td>
                    </tr>
                  ))}
              </tbody>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "1rem 0",
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <button
                    style={{ marginRight: "20px" }}
                    onClick={pagination.prev}
                  >
                    Previous Page
                  </button>
                  <span style={{ margin: "0 20px" }}>
                    Page {pagination.currentPage + 1}
                  </span>
                  <button
                    style={{ marginLeft: "20px" }}
                    onClick={pagination.next}
                  >
                    Next Page
                  </button>
                </div>
              </div>
            </table>
          </div>
        </>
      ) : (
        <p>Loading...</p>
      )}
    </Layout>
  );
}

export default KudurruIpLookupPage;
