export type User = {
  email: string;
  id: number;
  is_verified: boolean;
  pending_review_optouts: number;
  notes: string;
};

export type Users = User[];

export type Domain = {
  email: string;
  id: number;
  is_processed: boolean;
  is_reviewed: boolean;
  is_verified: boolean;
  name: string;
  is_checked?: boolean;
  num_domains: number;
  user_notes: string;
  domain_notes: string;
  user_id: number;
};

export type Domains = Domain[];

export type Book = {
  id: number;
  email: string;
  is_reviewed: boolean;
  is_rejected: boolean;
  entry: any;
  is_checked?: boolean;
};

export type Books = Book[];

export type Selection = {
  caption: string;
  id: number;
  is_rejected: boolean;
  is_reviewed: boolean;
  url: string;
  is_checked?: boolean;
  type?: ItemDataType;
};

export type Selections = Selection[];

export type NearDuplicate = {
  id: number;
  baseUrl: string | null;
  caption: string;
  ranking: number | null;
  similarity: number;
  sourceId: number;
  type_id: number;
  url: string;
  is_checked: boolean;
};

export type NonUrlSelection = {
  id: number;
  identifier: string;
  identifier_type: string;
  dataset_id: number;
  dataset_type_id: number;
  entry: any;
  is_reviewed: boolean;
  is_rejected: boolean;
  is_checked: boolean;
};

export enum ItemDataType {
  IMAGE = "IMAGE",
  AUDIO = "AUDIO",
  VIDEO = "VIDEO",
  TEXT = "TEXT",
  CODE = "CODE",
  OTHER = "OTHER",
}
