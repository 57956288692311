import React from "react";
import cn from "classnames";
import { Link, useLocation, useParams } from "react-router-dom";

import styles from "./NavBar.module.scss";

import {
  AppBar,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";

// icons
import HomeIcon from "@mui/icons-material/Home";
import PeopleIcon from "@mui/icons-material/People";
import DomainIcon from "@mui/icons-material/Domain";
import QueryStats from "@mui/icons-material/QueryStats";
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import WarningIcon from '@mui/icons-material/Warning';

const NavBar = () => {
  const { pathname } = useLocation();

  return (
    <AppBar className={styles.navWrapper} position="fixed">
      <Toolbar
        className={styles.nav}
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Typography className={styles.header} variant="h6" component="div">
          HIBT Downloader Admin
        </Typography>
        <List sx={{ display: "flex" }}>
          <ListItem>
            <Link
              className={cn(styles.link, {
                [styles.active]: pathname === "/",
              })}
              to="/"
            >
              <Box display="flex" alignItems="center">
                <ListItemIcon sx={{ marginRight: "-20px" }}>
                  <HomeIcon className={styles.icon} />
                </ListItemIcon>
                <ListItemText className={styles.text} primary="Home" />
              </Box>
            </Link>
          </ListItem>
          <ListItem>
            <Link
              className={cn(styles.link, {
                [styles.active]: pathname === "/users",
              })}
              to="/users"
            >
              <Box display="flex" alignItems="center">
                <ListItemIcon sx={{ marginRight: "-20px" }}>
                  <PeopleIcon className={styles.icon} />
                </ListItemIcon>
                <ListItemText className={styles.text} primary="Users" />
              </Box>
            </Link>
          </ListItem>

          <ListItem>
            <Link
              className={cn(styles.link, {
                [styles.active]: pathname === "/domains",
              })}
              to="/domains"
            >
              <Box display="flex" alignItems="center">
                <ListItemIcon sx={{ marginRight: "-20px" }}>
                  <DomainIcon className={styles.icon} />
                </ListItemIcon>
                <ListItemText className={styles.text} primary="Domains" />
              </Box>
            </Link>
          </ListItem>

          <ListItem>
            <Link
              className={cn(styles.link, {
                [styles.active]: pathname === "/nsfw/review/",
              })}
              to="/nsfw/review/"
            >
              <Box display="flex" alignItems="center">
                <ListItemIcon sx={{ marginRight: "-20px" }}>
                  <WarningIcon className={styles.icon} />
                </ListItemIcon>
                <ListItemText className={styles.text} primary="NSFW" />
              </Box>
            </Link>
          </ListItem>

        </List>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
