import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button, Box } from "@mui/material";
import Layout from "../../components/Layout";
import { END_POINT } from "../../consts";
import ErrorAlert from "../../components/ErrorAlert";
import styles from "./Home.module.scss";

const Home = () => {
  const [currentDownload, setCurrentDownload] = useState({ status: "STOPPED" });
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [downloadVersion, setDownloadVersion] = useState({
    lastModified: "",
    version: "",
  });

  return (
    <Layout>
      <Box><h1>Welcome!</h1></Box>

      <ErrorAlert
        isError={isError}
        setIsError={setIsError}
        errorMessage={errorMessage}
      />
    </Layout>
  );
};

export default Home;
