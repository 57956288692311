import React, { useEffect, useState } from "react";
import styles from "./DomainsPage.module.scss";
import Layout from "../../components/Layout";
import { Select, TextField } from "@spawning-inc/kiplu";
import { Domains } from "../../types";
import axios from "axios";
import { END_POINT } from "../../consts";
import DomainsTable from "./DomainsTable/DomainsTable";
import { Button, CircularProgress, Pagination } from "@mui/material";
import {
  getDomainComparator,
  sortedDomainsInformation,
} from "../../helpers/domainSorting";
import ErrorAlert from "../../components/ErrorAlert";

const itemsPerPageOptions = [
  { value: 2, name: "2" },
  { value: 5, name: "5" },
  { value: 20, name: "20" },
  { value: 50, name: "50" },
  { value: 100, name: "100" },
  { value: 500, name: "500" },
  { value: 1000, name: "1000" },
];

const DomainsPage = () => {
  const [currentDomains, setCurrentDomains] = useState<Domains>([]);
  const [totalDomains, setTotalDomains] = useState<Domains>([]);
  const [numSelected, setNumSelected] = useState(0);
  const [areAllDomainsReviewed, setAreAllDomainsReviewed] = useState(false);
  const [domainSearchTerm, setDomainSearchTerm] = useState("");
  const [emailSearchTerm, setEmailSearchTerm] = useState("");
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [sortOrderDir, setSortOrderDir] = useState<"asc" | "desc">("desc");
  const [orderValue, setOrderValue] = useState<
    "is_reviewed" | "is_verified" | "is_processed" | "id" | "num_domains"
  >("id");
  const [totalDomainsCount, setTotalDomainsCount] = useState(0);

  const [itemsPerPage, setItemsPerPage] = useState(
    itemsPerPageOptions[2].value
  );
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState({
    from: 0,
    to: itemsPerPage,
  });

  const getDomains = async (
    page: number,
    num: number,
    sort_by: string,
    sort_order: string,
    domain: string,
    email: string
  ) => {
    setIsLoading(true);
    try {
      let url = `${END_POINT}/domains?offset=${page}&limit=${num}&sort_order=${sort_order}&sort_by=${sort_by}`;
      if (domain !== "") {
        url = `${url}&domain=${domain}`;
      }
      if (email !== "") {
        url = `${url}&email=${email}`;
      }
      const data = await axios.get(url, {
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      });

      const { data: domains } = data;
      const headers = data.headers;
      const contentRange = headers["content-range"];
      const total = contentRange.split("/")[1];
      setTotalDomainsCount(Number(total));

      // added a 'is_checked' property to each domain
      const transformedSelections = domains.map((selection: Selection) => ({
        ...selection,
        is_checked: false,
      }));

      await setCurrentDomains(transformedSelections);
      setIsLoading(false);
      setIsError(false);
    } catch (error) {
      setIsLoading(false);

      setIsError(true);
      setErrorMessage("Failed to get list of domains");
    }
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    event.preventDefault();
    const from = (page - 1) * itemsPerPage;
    const to = itemsPerPage;

    setPage(page);
    setPagination({ ...pagination, from, to });
  };

  const handleSortRequest = (
    name: "is_reviewed" | "is_verified" | "is_processed" | "id" | "num_domains"
  ) => {
    const isAsc = sortOrderDir === "asc";
    const newSortOrder = isAsc ? "desc" : "asc";
    setOrderValue(name);
    setSortOrderDir(newSortOrder);
    getDomains(
      pagination.from,
      pagination.to,
      name,
      newSortOrder,
      domainSearchTerm,
      emailSearchTerm
    );
  };

  const handleItemsPerPageSelectChange = (
    target:
      | (EventTarget & HTMLInputElement)
      | (EventTarget & { value: unknown; name: string })
  ) => {
    setItemsPerPage(Number(target.value));
  };

  const updateDomain = async () => {
    try {
      const domainsToBeUpdated = currentDomains.filter(
        ({ is_checked }) => is_checked === true
      );

      await axios.put(
        `${END_POINT}/domains/`,
        {
          domains: domainsToBeUpdated.map((domain) => ({
            id: domain.id,
            is_verified: domain.is_verified,
            is_processed: domain.is_processed,
            is_reviewed: true
          })),
        },
        {
          headers: {
            Authorization: `${localStorage.getItem("token")}`,
          },
        }
      );

      await getDomains(
        pagination.from,
        pagination.to,
        orderValue,
        sortOrderDir,
        domainSearchTerm,
        emailSearchTerm
      );

      // iterate through the current domains list setting is_reviewed to true if they have is_checked
      setCurrentDomains(
        currentDomains.map((domain) => {
          if (domain.is_checked) {
            return { ...domain, is_reviewed: true };
          }
          return domain;
        })
      );

      setIsError(false);
    } catch (error) {
      setIsError(true);
      setErrorMessage("Failed to update domain settings.");
    }
  };

  const toggleVerifyDomain = async (status: boolean) => {
    try {
      const domainsToBeUpdated = currentDomains.filter(
        ({ is_checked }) => is_checked === true
      );

      await axios.put(
        `${END_POINT}/domains/`,
        {
          domains: domainsToBeUpdated.map((domain) => ({
            id: domain.id,
            is_verified: status,
            is_processed: domain.is_processed,
            is_reviewed: true
          })),
        },
        {
          headers: {
            Authorization: `${localStorage.getItem("token")}`,
          },
        }
      );

      await getDomains(
        pagination.from,
        pagination.to,
        orderValue,
        sortOrderDir,
        domainSearchTerm,
        emailSearchTerm
      );

      setIsError(false);
    } catch (error) {
      setIsError(true);
      setErrorMessage("Failed to verify domains.");
    }
  };

  useEffect(() => {
    getDomains(
      pagination.from,
      pagination.to,
      orderValue,
      sortOrderDir,
      domainSearchTerm,
      emailSearchTerm
    );

    setPagination({ ...pagination, to: itemsPerPage });
  }, [pagination.from, pagination.to, itemsPerPage]);

  useEffect(() => {
    const domainsChecked = currentDomains.filter(
      ({ is_checked }) => !!is_checked
    );

    const notReviewedDomains = currentDomains.filter(
      ({ is_reviewed }) => !is_reviewed
    );

    setAreAllDomainsReviewed(notReviewedDomains.length === 0);
    setNumSelected(domainsChecked.length);
  }, [currentDomains]);

  const handleSearch = () => {
    getDomains(
      pagination.from,
      pagination.to,
      orderValue,
      sortOrderDir,
      domainSearchTerm,
      emailSearchTerm
    );
  };

  const handleEnterSearch = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <Layout height="fit-content">
      <div className={styles.wrapper}>
        <h1>Domains</h1>

        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            {`Showing ${currentDomains.length} Domains`}

            <div className={styles.tableWrapper}>
              <div className={styles.tableControlsWrapper}>
                <TextField
                  className={styles.searchField}
                  placeholder="Search by domain name"
                  onChange={(e) => setDomainSearchTerm(e.target.value)}
                  onKeyDown={(e) => handleEnterSearch(e)}
                  value={domainSearchTerm}
                  InputProps={{
                    endAdornment: (
                      <button
                        className={styles.searchButton}
                        onClick={handleSearch}
                      >
                        search
                      </button>
                    ),
                    startAdornment: domainSearchTerm !== "" && (
                      <button
                        className={styles.clearButton}
                        onClick={() => {
                          setDomainSearchTerm("");
                          getDomains(
                            pagination.from,
                            pagination.to,
                            orderValue,
                            sortOrderDir,
                            "",
                            emailSearchTerm
                          );
                        }}
                      >
                        clear
                      </button>
                    ),
                  }}
                />
                <TextField
                  className={styles.searchField}
                  placeholder="Search by email"
                  onChange={(e) => setEmailSearchTerm(e.target.value)}
                  onKeyDown={(e) => handleEnterSearch(e)}
                  value={emailSearchTerm}
                  InputProps={{
                    endAdornment: (
                      <button
                        className={styles.searchButton}
                        onClick={handleSearch}
                      >
                        search
                      </button>
                    ),
                    startAdornment: emailSearchTerm !== "" && (
                      <button
                        className={styles.clearButton}
                        onClick={() => {
                          setEmailSearchTerm("");
                          getDomains(
                            pagination.from,
                            pagination.to,
                            orderValue,
                            sortOrderDir,
                            domainSearchTerm,
                            ""
                          );
                        }}
                      >
                        clear
                      </button>
                    ),
                  }}
                />

                {numSelected > 0 && (
                  <div className={styles.actionButtonsWrapper}>
                    <Button
                      disabled={numSelected <= 0 || areAllDomainsReviewed}
                      className={styles.reviewButton}
                      onClick={updateDomain}
                    >
                      {areAllDomainsReviewed
                        ? "All domains are reviewed"
                        : `Mark ${
                            numSelected > 0 ? numSelected : ""
                          } as Reviewed`}
                    </Button>

                    <div className={styles.dividerLine} />

                    <Button
                      disabled={numSelected <= 0}
                      className={styles.verifyButton}
                      onClick={() => toggleVerifyDomain(true)}
                    >
                      Verify
                    </Button>

                    <div className={styles.dividerLine} />

                    <Button
                      disabled={numSelected <= 0}
                      className={styles.verifyButton}
                      onClick={() => toggleVerifyDomain(false)}
                    >
                      Mark as not verified
                    </Button>
                  </div>
                )}

                <div className={styles.itemsPerPageSelect}>
                  <span>Items per page</span>

                  <Select
                    defaultValue={itemsPerPage}
                    options={itemsPerPageOptions}
                    onChange={({ target }) => {
                      handleItemsPerPageSelectChange(target);
                    }}
                  />
                </div>
              </div>

              {currentDomains.length > 0 ? (
                <>
                  <DomainsTable
                    domains={currentDomains}
                    orderValue={orderValue}
                    sortOrderDir={sortOrderDir}
                    numSelected={numSelected}
                    setDomains={setCurrentDomains}
                    handleSortRequest={handleSortRequest}
                  />
                  <div className={styles.paginationHolder}>
                    <Pagination
                      page={page}
                      count={Math.ceil(totalDomainsCount / itemsPerPage)}
                      onChange={handlePageChange}
                    />
                  </div>
                </>
              ) : (
                <p>No domains found</p>
              )}
            </div>
          </>
        )}

        <ErrorAlert
          isError={isError}
          setIsError={setIsError}
          errorMessage={errorMessage}
        />
      </div>
    </Layout>
  );
};

export default DomainsPage;
