import React from "react";

import ControlsBar from "../../../ControlsBar/ControlsBar";
import { Selections } from "../../../../../types";
import IndividualUserSelection from "../../../../IndividualUserPage/IndividualUserSelection/IndividualUserSelection";

import styles from "./ApprovedItemsTab.module.scss";

interface ApprovedItemsTabProps {
  approvedItems: Selections;
  handlePreviewClick: (url: string) => void;
  handleApprovedSelectAllCheckBoxClick: (
    e: React.ChangeEvent<HTMLInputElement>
  ) => void;
  handleCheckBoxClick: (
    e: React.ChangeEvent<HTMLInputElement>,
    id: number,
    index: number,

    status: "approved" | "rejected" | "unReviewed"
  ) => void;
  handleKeyDown: (
    e: React.KeyboardEvent<HTMLInputElement>,
    id: number,
    status: "approved" | "rejected" | "unReviewed"
  ) => void;
  handleAcceptanceClick: (
    type: "unReviewed" | "approved" | "rejected"
  ) => Promise<void>;
  handleRejectionClick: (
    type: "unReviewed" | "approved" | "rejected"
  ) => Promise<void>;
}

const ApprovedItemsTab: React.FC<ApprovedItemsTabProps> = ({
  approvedItems,
  handlePreviewClick,
  handleApprovedSelectAllCheckBoxClick,
  handleCheckBoxClick,
  handleKeyDown,
  handleAcceptanceClick,
  handleRejectionClick,
}) => {
  return (
    <div className={styles.tabContentWrapper}>
      <h1 className={styles.tabHeader}>Approved Items</h1>

      <ControlsBar
        message={`${approvedItems.length} approved items`}
        numberOfSelectedItems={
          approvedItems.filter(({ is_checked }) => is_checked).length
        }
        handleApprovedSelectAllCheckBoxClick={
          handleApprovedSelectAllCheckBoxClick
        }
        onApprove={() => handleAcceptanceClick("approved")}
        onReject={() => handleRejectionClick("approved")}
      />
      <div className={styles.imagesHolder}>
        {approvedItems.map((item, idx) => (
          <div key={item.id} className={styles.imageWrapper}>
            <IndividualUserSelection
              index={idx}
              currentUserSelection={item}
              handlePreviewClick={handlePreviewClick}
              handleCheckBoxClick={handleCheckBoxClick}
              handleKeyDown={handleKeyDown}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ApprovedItemsTab;
