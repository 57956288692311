import React, { useEffect, useState } from "react";
import axios from "axios";

type DomainActivity = {
  Domain: string;
  Total: number;
  Host: string;
  ip: string | null;
  timestamp: string | null;
};

const DomainActivityTable: React.FC = () => {
  const [data, setData] = useState<DomainActivity[]>([]);
  const [totalDomains, setTotalDomains] = useState<number>(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      axios
        .get("https://disneycarscase.info/domain_activity", {
          withCredentials: false,
        })
        .then((res) => {
          // Replace NaN with null in the response string to create a valid JSON string
          const correctedData = res.data.replace(/NaN|NaT/g, "null");

          // Parse the JSON string to a JavaScript object
          const parsedData = JSON.parse(correctedData);

          // If parsedData is an array, use it. Otherwise wrap it in an array
          const dataArray = Array.isArray(parsedData)
            ? parsedData
            : [parsedData];

          // Sort the array by most recent timestamp, putting 'NaT' or invalid dates at the end
          // Sort the array by most recent timestamp, putting 'NaT' or invalid dates at the end
          const sortedData = [...dataArray].sort((a, b) => {
            const dateA = a.timestamp ? new Date(a.timestamp) : null;
            const dateB = b.timestamp ? new Date(b.timestamp) : null;

            if (!dateA || !dateB) {
              if (!dateA && !dateB) return 0; // Both are NaT, leave their order as is
              if (!dateA) return 1; // dateA is NaT, so it should go after
              if (!dateB) return -1; // dateB is NaT, so it should go after
            }

            // If both dates are valid, sort by most recent
            return dateB > dateA ? 1 : -1;
          });

          setData(sortedData);
        })
        .catch((err) => {
          console.error(err);
        });
    }, 500); // Fetch new data every 0.5 seconds

    return () => clearInterval(intervalId); // Clean up on unmount
  }, []);

  // Calculate the total sum of all "Total" counts where the timestamp is not 'NaT'
  const totalSum = data.reduce((sum, item) => {
    const date = item.timestamp ? new Date(item.timestamp) : null;
    if (date && !isNaN(date.getTime())) {
      // If date is valid, add the Total to the sum
      sum += item.Total;
    }
    return sum;
  }, 0);

  // Calculate the total sum of active domains where the ip is not null
  const activeDomains = data.reduce((sum, item) => {
    if (item.ip) {
      // If ip is not null, increment the sum
      sum += 1;
    }
    return sum;
  }, 0);

  // Calculate the total sum of active domains where the ip is not null
  const potential = data.reduce((sum, item) => {
    if (item.Total) {
      // If ip is not null, increment the sum
      sum += item.Total;
    }
    return sum;
  }, 0);

  return (
    <div>
      <div>Total Images: {potential}</div>
      <div>Active Images: {totalSum}</div>
      <div>Total Domains: {data.length}</div>
      <div>Active Domains: {activeDomains}</div>

      <table style={{ width: "100%", minWidth: "100%", margin: "1em 0" }}>
        <thead>
          <tr>
            <th>Domain</th>
            <th>Host</th>
            <th>Activity</th>
            <th>Time</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td>{item.Domain}</td>
              <td>{item.Host}</td>
              <td>
                {" "}
                <a
                  href={`./kudurru/${item.ip}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {item.ip}
                </a>
              </td>
              <td>{item.timestamp}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DomainActivityTable;
