import React, { useState } from "react";
import cn from "classnames";
import styles from "./IndividualUserSelection.module.scss";
import { ItemDataType, Selection } from "../../../types";
import BrokenStateDataGridCard from "../../../components/BrokenStateDataGridCard/BrokenStateDataGridCard";
import AudioPlayer from "../../../components/AudioPlayer/AudioPlayer";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { IconButton, Tooltip } from "@mui/material";

interface IndividualUserSelectionProps {
  index: number;
  currentUserSelection: Selection;
  handlePreviewClick: (url: string) => void;
  handleCheckBoxClick: (
    e: React.ChangeEvent<HTMLInputElement>,
    id: number,
    index: number,
    status: "approved" | "rejected" | "unReviewed"
  ) => void;
  handleKeyDown: (
    e: React.KeyboardEvent<HTMLInputElement>,
    id: number,
    status: "approved" | "rejected" | "unReviewed"
  ) => void;
}

const IndividualUserSelection: React.FC<IndividualUserSelectionProps> = ({
  index,
  currentUserSelection: { id, is_checked, url, is_reviewed, is_rejected, type },
  handlePreviewClick,
  handleCheckBoxClick,
  handleKeyDown,
}) => {
  const [brokenItemUrl, setBrokenItemUrl] = useState("");

  const getStatusOfItem = () => {
    if (!is_reviewed) {
      return "unReviewed";
    } else if (is_reviewed && is_rejected) {
      return "rejected";
    } else {
      return "approved";
    }
  };

  const status = getStatusOfItem();

  const dataSpecificElement: Record<ItemDataType, React.ReactNode> = {
    [ItemDataType.VIDEO]: (
      <div className={styles.videoPlayerWrapper}>
        <video
          src={url}
          controls
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        >
          <track kind="captions" />
        </video>
      </div>
    ),
    [ItemDataType.AUDIO]: (
      <div className={styles.audioPlayerWrapper}>
        <AudioPlayer url={url} className={styles.audioPlayer} />
      </div>
    ),
    [ItemDataType.CODE]: (
      <span className={styles.codePlaceholder}>
        {/* <CodeIcon fontSize="large" /> */}
        {/* <p>{extension}</p> */}
        code
      </span>
    ),
    [ItemDataType.TEXT]: null,
    [ItemDataType.IMAGE]: (
      <>
        <div
          className={styles.image}
          style={{ backgroundImage: `url(${url})` }}
        />
        <img
          src={url}
          style={{
            display: "none",
          }}
          onError={() => {
            setBrokenItemUrl(url);
          }}
        />
      </>
    ),
    [ItemDataType.OTHER]: (
      <span>
        <div
          className={styles.image}
          style={{ backgroundImage: `url(${url})` }}
          />
        <img
          src={url}
          alt={"no caption found"}
          style={{
            display: "none",
          }}
          onError={() => {
            setBrokenItemUrl(url);
          }}
        />
      </span>
    ),
  };

  return (
    <div className={styles.imageWrapper} key={id}>
      {type !== ItemDataType.AUDIO && type !== ItemDataType.VIDEO && (
        <div className={styles.hoverBox}>
          <div className={styles.innerHoverBox}>
            <button
              className={styles.previewButton}
              onClick={() => handlePreviewClick(url)}
            >
              PREVIEW
            </button>
          </div>
        </div>
      )}

      <span className={styles.selectionWrapper}>
        <div className={styles.selectionInfoWrapper}>
          <input
            checked={is_checked}
            onChange={(e) => handleCheckBoxClick(e, id, index, status)}
            onKeyDown={(e) => handleKeyDown(e, id, status)}
            className={styles.checkBox}
            type="checkbox"
          />
          <Tooltip title="open url in a new link from direct source">
            <IconButton
              onClick={() => window.open(url, "_blank", "noopener noreferrer")}
            >
              <OpenInNewIcon />
            </IconButton>
          </Tooltip>
        </div>
        <div
          className={cn({
            [styles.isChecked]: is_checked,
          })}
        >
          {brokenItemUrl !== url ? (
            <>{dataSpecificElement[type as ItemDataType]}</>
          ) : (
            <BrokenStateDataGridCard />
          )}
        </div>
      </span>
    </div>
  );
};

export default IndividualUserSelection;
