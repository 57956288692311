type ChartData = {
  labels: string[];
  datasets: {
    label: string;
    data: number[];
    backgroundColor: string[];
  }[];
};

type DomainColorListProps = {
  chartData: ChartData;
};

export const DomainColorList: React.FC<DomainColorListProps> = ({
  chartData,
}) => {
  return (
    <ul
      style={{
        listStyleType: "none",
        maxHeight: "400px", // change this value as you need
        overflow: "auto",
        width: "max-content",
      }}
    >
      {chartData.labels.map((label: string, i: number) => (
        <li key={label}>
          <span
            style={{
              display: "inline-block",
              width: "20px",
              height: "20px",
              marginRight: "10px",
              backgroundColor: chartData.datasets[0].backgroundColor[i],
            }}
          ></span>
          {label}
        </li>
      ))}
    </ul>
  );
};
