import React from "react";
import { Table } from "@spawning-inc/kiplu";
import { Checkbox, TableSortLabel } from "@mui/material";
import { useState } from "react";
import { Domains } from "../../../types";

import styles from "./DomainsTable.module.scss";
import Lozenge from "../../../components/Lozenge/Lozenge";
import { NoteAdd, NoteAlt } from "@mui/icons-material";
import Button from '@mui/material/Button';
import NotesModal from "../../../components/NotesModal/NotesModal";
import axios from "axios";
import { END_POINT } from "../../../consts";

interface DomainsTableProps {
  domains: Domains;
  orderValue: "is_reviewed" | "is_verified" | "is_processed" | "id" | "num_domains";
  sortOrderDir: "asc" | "desc";
  numSelected: number;
  setDomains: React.Dispatch<React.SetStateAction<Domains>>;
  handleSortRequest: (
    name: "is_reviewed" | "is_verified" | "is_processed" | "id" | "num_domains"
  ) => void;
}

const DomainsTable: React.FC<DomainsTableProps> = ({
  domains,
  orderValue,
  sortOrderDir,
  numSelected,
  setDomains,
  handleSortRequest,
}) => {
  const [selectedNotes, setSelectedNotes] = useState({id: 0, notes: "", mode:""});
  const [notesOpen, setNotesOpen] = useState(false);

  const handleSortClick =
    (name: "is_reviewed" | "is_verified" | "is_processed" | "id" | "num_domains") => () => {
      handleSortRequest(name);
    };

  const handleOnSelectAllClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;

    const selectAllDomains = domains.map((domain) => ({
      ...domain,
      is_checked: checked,
    }));

    setDomains(selectAllDomains);
  };

  const handleOnSelectAllKeyDown = (
    e: React.KeyboardEvent<HTMLButtonElement>
  ) => {
    if (e.key === "Enter") {
      const selectAllDomains = domains.map((domain) => ({
        ...domain,
        is_checked: true,
      }));

      setDomains(selectAllDomains);
    }

    if (e.key === "Backspace" || e.key === "Delete") {
      const selectAllDomains = domains.map((domain) => ({
        ...domain,
        is_checked: false,
      }));

      setDomains(selectAllDomains);
    }
  };

  const handleDomainRowClick = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    id: number,
    is_checked: boolean
  ) => {
    if (e.target) {
      const target = e.target as HTMLElement;
      if (target.classList.contains("dontopen")) {
        return;
      }
    }

    (e.target as HTMLInputElement).checked = !is_checked;

    const { checked } = e.target as HTMLInputElement;

    const newSelectionArray = domains.map((domain) =>
      domain.id === id ? { ...domain, is_checked: checked } : domain
    );

    setDomains(newSelectionArray);
  };

  const handleKeyDown = (
    e:
      | React.KeyboardEvent<HTMLButtonElement>
      | React.KeyboardEvent<HTMLTableRowElement>,
    id: number,
    is_checked: boolean
  ) => {
    if (e.key === "Enter") {
      (e.target as HTMLInputElement).checked = !is_checked;

      const { checked } = e.target as HTMLInputElement;

      const newSelectionArray = domains.map((domain) =>
        domain.id === id ? { ...domain, is_checked: checked } : domain
      );

      setDomains(newSelectionArray);
    }

    if (e.key === "Backspace" || e.key === "Delete") {
      (e.target as HTMLInputElement).checked = false;

      const { checked } = e.target as HTMLInputElement;

      const newSelectionArray = domains.map((domain) =>
        domain.id === id ? { ...domain, is_checked: checked } : domain
      );

      setDomains(newSelectionArray);
    }
  };

  const openNotes = (id: number, notes: string, mode: string) => {
    setSelectedNotes({id, notes, mode});
    setNotesOpen(true);
  }

  const saveNotes = async (id: number, notes: string) => {
    if (selectedNotes.mode === "domain") {
      await axios.put(`${END_POINT}/domains/${id}`, {
        notes: notes
      },
      {
        headers: {
          "Authorization": `${localStorage.getItem("token")}`
        }
      });
      setNotesOpen(false);
      setSelectedNotes({id: 0, notes: "", mode: ""});

      // update the domain
      const domain = domains.find((domain) => domain.id === id);
      if (domain) {
        domain.domain_notes = notes;
      }
    } else {
      await axios.put(`${END_POINT}/users/${id}`, {
        notes: notes
      },
      {
        headers: {
          "Authorization": `${localStorage.getItem("token")}`
        }
      });
      setNotesOpen(false);
      setSelectedNotes({id: 0, notes: "", mode: ""});
  
      // update user
      const domain = domains.find((domain) => domain.id === id);
      if (domain) {
        domain.user_notes = notes;
      }
    }
  }

  return (
    <>
    <Table>
      <Table.Head>
        <Table.Row>
          <Table.Cell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < domains.length}
              checked={domains.length > 0 && numSelected === domains.length}
              onChange={(e) => handleOnSelectAllClick(e)}
              onKeyDown={(e) => handleOnSelectAllKeyDown(e)}
              inputProps={{
                "aria-label": "select all desserts",
              }}
            />
          </Table.Cell>
          <Table.Cell>
            <TableSortLabel
              className={styles.sortLable}
              active={orderValue === "id"}
              direction={orderValue === "id" ? sortOrderDir : "asc"}
              onClick={handleSortClick("id")}
            >
              Domain ID
            </TableSortLabel>
          </Table.Cell>
          <Table.Cell>Domain</Table.Cell>
          <Table.Cell>User Email</Table.Cell>
          <Table.Cell>
            <TableSortLabel
              className={styles.sortLable}
              active={orderValue === "is_reviewed"}
              direction={orderValue === "is_reviewed" ? sortOrderDir : "asc"}
              onClick={handleSortClick("is_reviewed")}
            >
              Reviewed
            </TableSortLabel>
          </Table.Cell>
          <Table.Cell>
            Number of Domains
          </Table.Cell>
          <Table.Cell>
            <TableSortLabel
              className={styles.sortLable}
              active={orderValue === "is_verified"}
              direction={orderValue === "is_verified" ? sortOrderDir : "asc"}
              onClick={handleSortClick("is_verified")}
            >
              Verified
            </TableSortLabel>
          </Table.Cell>
          <Table.Cell>User Notes</Table.Cell>
          <Table.Cell>Domain Notes</Table.Cell>
        </Table.Row>
      </Table.Head>

      <Table.Body>
        {domains.map(
          ({
            id,
            email,
            name,
            is_verified,
            is_processed,
            is_reviewed,
            is_checked,
            num_domains,
            user_notes, 
            domain_notes,
            user_id
          }) => (
            <Table.Row
              key={id}
              className={styles.domainRow}
              onClick={(e) => handleDomainRowClick(e, id, is_checked || false)}
              onKeyDown={(e) => handleKeyDown(e, id, is_checked || false)}
            >
              <Table.Cell padding="checkbox">
                <Checkbox
                  color="primary"
                  checked={is_checked}
                  onChange={(e) =>
                    handleDomainRowClick(e, id, is_checked || false)
                  }
                  onKeyDown={(e) => {
                    handleKeyDown(e, id, is_checked || false);
                  }}
                  inputProps={{
                    "aria-label": "select all desserts",
                  }}
                />
              </Table.Cell>
              <Table.Cell>{id}</Table.Cell>
              <Table.Cell>{name}</Table.Cell>
              <Table.Cell>{email}</Table.Cell>
              <Table.Cell>
                <Lozenge
                  status={is_reviewed ? "positive" : "negative"}
                  type="reviewed"
                />
              </Table.Cell>
              <Table.Cell>{num_domains}</Table.Cell>
              <Table.Cell>
                <Lozenge
                  status={is_verified ? "positive" : "negative"}
                  type="verified"
                />
              </Table.Cell>
              <Table.Cell>
                <Button className="dontopen" onClick={(e) => {openNotes(id, user_notes, "user")}} variant="contained" color={user_notes ? "info" : "success"} endIcon={user_notes ? <NoteAlt /> : <NoteAdd />}>
                  { user_notes ? "Edit" : "Add" }
                </Button>
              </Table.Cell>
              <Table.Cell>
                <Button className="dontopen" onClick={(e) => {openNotes(id, domain_notes, "domain")}} variant="contained" color={domain_notes ? "info" : "success"} endIcon={domain_notes ? <NoteAlt /> : <NoteAdd />}>
                  { domain_notes ? "Edit" : "Add" }
                </Button>
              </Table.Cell>
            </Table.Row>
          )
        )}
      </Table.Body>
    </Table>
    <NotesModal
      isOpen={notesOpen}
      setIsOpen={setNotesOpen}
      notes={selectedNotes.notes}
      onSave={(newNotesText) => {
        saveNotes(selectedNotes.id, newNotesText);
      }}/>
    </>
  );
};

export default DomainsTable;
