import React, { useEffect, useState } from "react";
import axios from "axios";
import ErrorAlert from "../../components/ErrorAlert";
import Layout from "../../components/Layout";
import { END_POINT } from "../../consts";
import { User, Users } from "../../types";
import { Select, TextField } from "@spawning-inc/kiplu";

import styles from "./UsersPage.module.scss";
import UsersTable from "./UsersTable/UsersTable";
import { CircularProgress, Pagination } from "@mui/material";
import {
  getComparator,
  sortedUsersInformation,
} from "../../helpers/userSorting";

const itemsPerPageOptions = [
  { value: 5, name: "5" },
  { value: 20, name: "20" },
  { value: 50, name: "50" },
  { value: 100, name: "100" },
  { value: 500, name: "500" },
  { value: 1000, name: "1000" },
];

const UsersPage = () => {
  const [currentUsers, setCurrentUsers] = useState<Users>([]);
  const [totalUsers, setTotalUsers] = useState<Users>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [totalUsersCount, setTotalUsersCount] = useState(0);

  const [sortOrderDir, setSortOrderDir] = useState<"asc" | "desc">("desc");
  const [orderValue, setOrderValue] = useState<
    "pending_review_optouts"  | "is_verified" | "id"
  >("pending_review_optouts");

  const [itemsPerPage, setItemsPerPage] = useState(
    itemsPerPageOptions[2].value
  );

  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState({
    from: 0,
    to: itemsPerPage,
  });


  const getUsers = async (page: number, num: number, sort_by: string, sort_order: string, email: string) => {
    setIsLoading(true);

    try {
      let url = `${END_POINT}/users?offset=${page}&limit=${num}&sort_order=${sort_order}&sort_by=${sort_by}`;
      if (email !== "") {
        url = `${END_POINT}/users?offset=${page}&limit=${num}&sort_order=${sort_order}&sort_by=${sort_by}&email=${email}`;
      }
      const data = await axios.get(
        url,
        {
          headers: {
            "Authorization": `${localStorage.getItem('token')}`,
          }
        }
      );
      const { data: users } = data;

      // get response headers content-range to get max number of records
      const headers = data.headers;
      const contentRange = headers["content-range"];
      const total = contentRange.split("/")[1];
      setTotalUsersCount(Number(total));

      await setCurrentUsers(users);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setIsError(true);
      setErrorMessage("Failed to get list of users");
    }
  };

  useEffect(() => {
    getUsers(pagination.from, pagination.to, orderValue, sortOrderDir, searchTerm);

    setPagination({ ...pagination, to: itemsPerPage });
  }, [pagination.from, pagination.to, itemsPerPage]);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    event.preventDefault();
    const from = (page - 1) * itemsPerPage;
    const to = itemsPerPage;

    setPage(page);
    setPagination({ ...pagination, from, to });
  };

  const handleSortRequest = (
    name: "pending_review_optouts" | "is_verified" | "id" 
  ) => {
    const isAsc = sortOrderDir === "asc";
    const newSortOrder = isAsc ? "desc" : "asc";
    setOrderValue(name);
    setSortOrderDir(newSortOrder);
    getUsers(pagination.from, pagination.to, name, newSortOrder, searchTerm);
  };

  const handleItemsPerPageSelectChange = (
    target:
      | (EventTarget & HTMLInputElement)
      | (EventTarget & { value: unknown; name: string })
  ) => {
    setItemsPerPage(Number(target.value));
  };

  const handleSearch = () => {
    getUsers(pagination.from, pagination.to, orderValue, sortOrderDir, searchTerm);
  };

  const handleEnterSearch = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <Layout height="fit-content">
      <div className={styles.wrapper}>
        <h1 className={styles.header}>users</h1>

        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            {`Showing ${currentUsers.length} Users`}

            <div className={styles.tableWrapper}>
              <div className={styles.tableControlsWrapper}>
                <TextField
                  className={styles.searchField}
                  placeholder="Search by email"
                  onChange={(e) => setSearchTerm(e.target.value)}
                  onKeyDown={(e) => handleEnterSearch(e)}
                  value={searchTerm}
                  InputProps={{
                    endAdornment: (
                      <button
                        className={styles.searchButton}
                        onClick={handleSearch}
                      >
                        search
                      </button>
                    ),
                    startAdornment: searchTerm !== "" && (
                      <button
                        className={styles.clearButton}
                        onClick={() => {
                          setSearchTerm("");
                          getUsers(pagination.from, pagination.to, orderValue, sortOrderDir, "");
                        }}
                      >
                        clear
                      </button>
                    ),
                  }}
                />

                <div className={styles.itemsPerPageSelect}>
                  <span>Items per page</span>
                  <Select
                    defaultValue={itemsPerPage}
                    options={itemsPerPageOptions}
                    onChange={({ target }) => {
                      handleItemsPerPageSelectChange(target);
                    }}
                  />
                </div>
              </div>

              {currentUsers.length > 0 ? (
                <>
                  <UsersTable
                    users={currentUsers}
                    orderValue={orderValue}
                    sortOrderDir={sortOrderDir}
                    handleSortRequest={handleSortRequest}
                  />
                  <div className={styles.paginationHolder}>
                    <Pagination
                      page={page}
                      count={Math.ceil(totalUsersCount / itemsPerPage)}
                      onChange={handlePageChange}
                    />
                  </div>
                </>
              ) : (
                <p>No users found</p>
              )}
            </div>
          </>
        )}

        <ErrorAlert
          isError={isError}
          setIsError={setIsError}
          errorMessage={errorMessage}
        />
      </div>
    </Layout>
  );
};

export default UsersPage;
