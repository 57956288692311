import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

import Layout from "../../components/Layout";
import ErrorAlert from "../../components/ErrorAlert";
import { CircularProgress, Tooltip } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

import { END_POINT } from "../../consts";
import { Domains, Selections, User } from "../../types";

import styles from "./UserItemsPage.module.scss";
import UserItemsPageContent from "./UserItemsPageContent/UserItemsPageContent";
import UsersDetails from "./UsersDetails/UsersDetails";
import { classifyData } from "../../helpers/dataClassifier";

const UserItemsPage = () => {
  const { id: userId } = useParams();

  const [currentUser, setCurrentUser] = useState<User>();
  const [currentUsersDomains, setCurrentUsersDomains] = useState<Domains>();

  const [isLoading, setIsLoading] = useState(false);
  const [isUsersItemsLoading, setIsUsersItemsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [unReviewedItems, setUnReviewedItems] = useState<Selections>([]);
  const [approvedItems, setApprovedItems] = useState<Selections>([]);
  const [rejectedItems, setRejectedItems] = useState<Selections>([]);

  const getUser = async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.get(`${END_POINT}/users/${userId}/`, {
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      });

      await setCurrentUser(data);
      setIsError(false);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setIsError(true);
      setErrorMessage("Failed to get user");
    }
  };

  const getUsersDomainsInformation = async () => {
    try {
      const url = `${END_POINT}/domains?email=${currentUser?.email}`;
      const { data } = await axios.get(url, {
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      });

      setCurrentUsersDomains(data);
    } catch (error) {
      setErrorMessage("Failed to get user's domains");
    }
  };

  const getUsersUnReviewedOptOuts = async () => {
    const authToken = `${localStorage.getItem("token")}`;

    try {
      let allSelections: Selections = [];
      let offset = 0;
      const limit = 5000;
      let total = 0;

      do {
        const response = await axios.get(
          `${END_POINT}/users/${userId}/selections?offset=${offset}&limit=${limit}&is_reviewed=false`,
          {
            headers: {
              Authorization: authToken,
            },
          }
        );

        // add a type property and is_checked property to each
        // selection in classifyData helper function
        const classifiedData = classifyData(response.data);

        total = parseInt(response.headers["content-range"].split("/")[1], 10);
        const selections = classifiedData;

        allSelections = allSelections.concat(selections);

        offset += response.data.length;
      } while (offset < total);

      setUnReviewedItems(allSelections);
      setIsError(false);
    } catch (error) {
      setIsError(true);
      setErrorMessage("Failed to get list of user's opt outs");
    }
  };

  const getUsersApprovedOptOuts = async () => {
    const authToken = `${localStorage.getItem("token")}`;

    try {
      let allSelections: Selections = [];
      let offset = 0;
      const limit = 5000;
      let total = 0;

      do {
        const response = await axios.get(
          `${END_POINT}/users/${userId}/selections?offset=${offset}&limit=${limit}&is_reviewed=true&is_rejected=false`,
          {
            headers: {
              Authorization: authToken,
            },
          }
        );

        // add a type property and is_checked property to each
        // selection in classifyData helper function
        const classifiedData = classifyData(response.data);

        total = parseInt(response.headers["content-range"].split("/")[1], 10);
        const selections = classifiedData;

        allSelections = allSelections.concat(selections);

        offset += selections.length;
      } while (offset < total);

      setApprovedItems(allSelections);
      setIsError(false);
    } catch (error) {
      setIsError(true);
      setErrorMessage("Failed to get list of user's opt outs");
    }
  };

  const getUsersRejectedOptOuts = async () => {
    const authToken = `${localStorage.getItem("token")}`;

    try {
      let allSelections: Selections = [];
      let offset = 0;
      const limit = 5000;
      let total = 0;

      do {
        const response = await axios.get(
          `${END_POINT}/users/${userId}/selections?offset=${offset}&limit=${limit}&is_reviewed=true&is_rejected=true`,
          {
            headers: {
              Authorization: authToken,
            },
          }
        );

        // add a type property and is_checked property to each
        // selection in classifyData helper function
        const classifiedData = classifyData(response.data);

        total = parseInt(response.headers["content-range"].split("/")[1], 10);
        const selections = classifiedData;

        allSelections = allSelections.concat(selections);

        offset += selections.length;
      } while (offset < total);

      setRejectedItems(allSelections);
      setIsError(false);
    } catch (error) {
      setIsError(true);
      setErrorMessage("Failed to get list of user's opt outs");
    }
  };

  const getUserOptOuts = async () => {
    getUsersApprovedOptOuts();
    getUsersRejectedOptOuts();
    getUsersUnReviewedOptOuts();
  };

  useEffect(() => {
    getUser();
  }, [userId]);

  useEffect(() => {
    const getUserOptOuts = async () => {
      setIsUsersItemsLoading(true);

      try {
        await Promise.all([
          getUsersUnReviewedOptOuts(),
          getUsersApprovedOptOuts(),
          getUsersRejectedOptOuts(),
        ]);
      } catch (error) {
        setIsError(true);
        setErrorMessage("Failed to get user's opt outs");
      } finally {
        setIsUsersItemsLoading(false);
      }
    };

    if (currentUser) {
      getUserOptOuts();
      getUsersDomainsInformation();
    }
  }, [currentUser]);

  if (!currentUser) {
    return null;
  }

  const handleAcceptanceClick = async (
    type: "unReviewed" | "approved" | "rejected"
  ) => {
    setIsLoading(true);
    const itemsToSubmit = {
      unReviewed: unReviewedItems,
      approved: approvedItems,
      rejected: rejectedItems,
    };

    try {
      const selectedItems = itemsToSubmit[type].filter(
        ({ is_checked }) => is_checked === true
      );

      const acceptedItems = selectedItems.map(({ id }) => ({
        id,
        is_rejected: false,
      }));

      const { data } = await axios.put(
        `${END_POINT}/selections/`,
        { rejections: acceptedItems },
        {
          headers: {
            Authorization: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (data.detail === "success") {
        getUserOptOuts();
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setIsError(true);
      setErrorMessage("Failed to accept items");
    }
  };

  const handleRejectionClick = async (
    type: "unReviewed" | "approved" | "rejected"
  ) => {
    setIsLoading(true);

    const itemsToSubmit = {
      unReviewed: unReviewedItems,
      approved: approvedItems,
      rejected: rejectedItems,
    };

    try {
      const selectedItems = itemsToSubmit[type].filter(
        ({ is_checked }) => is_checked === true
      );

      const rejectedItems = selectedItems.map(({ id }) => ({
        id,
        is_rejected: true,
      }));

      const { data } = await axios.put(
        `${END_POINT}/selections/`,
        { rejections: rejectedItems },
        {
          headers: {
            Authorization: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (data.detail === "success") {
        getUserOptOuts();
        setIsLoading(false);
      }
    } catch (error) {
      setIsError(true);
      setIsLoading(false);

      setErrorMessage("Failed to accept items");
    }
  };

  if (isLoading) {
    return (
      <div className={styles.loadingState}>
        <CircularProgress />
        Loading...
      </div>
    );
  }

  return (
    <Layout height="fit-content">
      {isLoading && (
        <div className={styles.loadingState}>
          <CircularProgress />
          Loading user's data
        </div>
      )}

      <Tooltip title="Scroll to top of page">
        <button
          className={styles.scrollToTopButton}
          onClick={() => {
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            });
          }}
        >
          <ArrowUpwardIcon />
        </button>
      </Tooltip>

      <div className={styles.content}>
        <UsersDetails user={currentUser} domains={currentUsersDomains} />

        <UserItemsPageContent
          isUsersItemsLoading={isUsersItemsLoading}
          approvedItems={approvedItems}
          setApprovedItems={setApprovedItems}
          rejectedItems={rejectedItems}
          setRejectedItems={setRejectedItems}
          unReviewedItems={unReviewedItems}
          setUnReviewedItems={setUnReviewedItems}
          handleAcceptanceClick={handleAcceptanceClick}
          handleRejectionClick={handleRejectionClick}
        />
      </div>

      <ErrorAlert
        isError={isError}
        setIsError={setIsError}
        errorMessage={errorMessage}
      />
    </Layout>
  );
};

export default UserItemsPage;
