import React, { useState } from "react";
import cn from "classnames";

import { Tab, TabList, TabListItem, Tabs } from "../../../components/Tabs/Tabs";
import { Selections } from "../../../types";

import UnReviewedItemsTab from "./UserItemsTabs/UnReviewedItemsTab/UnReviewedItemsTab";
import ApprovedItemsTab from "./UserItemsTabs/ApprovedItemsTab/ApprovedItemsTab";
import RejectedItemsTab from "./UserItemsTabs/RejectedItemsTab/RejectedItemsTab";
import { Modal } from "@spawning-inc/kiplu";
import { CircularProgress } from "@mui/material";

import styles from "./UserItemsPageContent.module.scss";

interface UserItemsPageContentProps {
  isUsersItemsLoading: boolean;
  approvedItems: Selections;
  rejectedItems: Selections;
  unReviewedItems: Selections;
  setApprovedItems: React.Dispatch<React.SetStateAction<Selections>>;
  setRejectedItems: React.Dispatch<React.SetStateAction<Selections>>;
  setUnReviewedItems: React.Dispatch<React.SetStateAction<Selections>>;
  handleAcceptanceClick: (
    type: "unReviewed" | "approved" | "rejected"
  ) => Promise<void>;
  handleRejectionClick: (
    type: "unReviewed" | "approved" | "rejected"
  ) => Promise<void>;
}

const UserItemsPageContent: React.FC<UserItemsPageContentProps> = ({
  isUsersItemsLoading,
  approvedItems,
  rejectedItems,
  unReviewedItems,
  setApprovedItems,
  setRejectedItems,
  setUnReviewedItems,
  handleAcceptanceClick,
  handleRejectionClick,
}) => {
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [previewUrl, setPreviewUrl] = useState("");
  const [lastClickedIndex, setLastClickedIndex] = useState<number | null>(null);

  const currentItems = {
    approved: {
      items: approvedItems,
      setItems: setApprovedItems,
    },
    rejected: {
      items: rejectedItems,
      setItems: setRejectedItems,
    },
    unReviewed: {
      items: unReviewedItems,
      setItems: setUnReviewedItems,
    },
  };

  const handleCheckBoxClick = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: number,
    index: number,
    status: "approved" | "rejected" | "unReviewed"
  ) => {
    // @ts-ignore shiftKey is defined for click events
    const shiftKey = e.nativeEvent.shiftKey;
    const { checked } = e.target;

    const { items, setItems } = currentItems[status];

    if (shiftKey && lastClickedIndex !== null) {
      // If Shift key is held, update the selection for a range of items
      const start = Math.min(lastClickedIndex, index);
      const end = Math.max(lastClickedIndex, index);

      const newSelectionArray = items.map((selection, idx) =>
        idx >= start && idx <= end
          ? { ...selection, is_checked: checked }
          : selection
      );

      setItems(newSelectionArray);
    } else {
      // Normal click, update only the clicked item
      const newSelectionArray = items.map((selection) =>
        selection.id === id ? { ...selection, is_checked: checked } : selection
      );

      setItems(newSelectionArray);
      setLastClickedIndex(index); // Update last clicked index for future Shift + Click operations
    }
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    id: number,
    status: "approved" | "rejected" | "unReviewed"
  ) => {
    const { items, setItems } = currentItems[status];

    if (e.key === "Enter") {
      (e.target as HTMLInputElement).checked = true;

      const { checked } = e.target as HTMLInputElement;

      const newSelectionArray = items.map((selection) =>
        selection.id === id ? { ...selection, is_checked: checked } : selection
      );

      setItems(newSelectionArray);
    }

    if (e.key === "Backspace" || e.key === "Delete") {
      (e.target as HTMLInputElement).checked = false;

      const { checked } = e.target as HTMLInputElement;

      const newSelectionArray = items.map((selection) =>
        selection.id === id ? { ...selection, is_checked: checked } : selection
      );

      setItems(newSelectionArray);
    }
  };

  const handleUnReviewedSelectAllCheckBoxClick = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { checked } = e.target;

    const selectAllItems = unReviewedItems.map((searchResult) => ({
      ...searchResult,
      is_checked: checked,
    }));

    setUnReviewedItems(selectAllItems);
  };

  const handleApprovedSelectAllCheckBoxClick = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { checked } = e.target;

    const selectAllItems = approvedItems.map((searchResult) => ({
      ...searchResult,
      is_checked: checked,
    }));

    setApprovedItems(selectAllItems);
  };

  const handleRejectedSelectAllCheckBoxClick = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { checked } = e.target;

    const selectAllItems = rejectedItems.map((searchResult) => ({
      ...searchResult,
      is_checked: checked,
    }));

    setRejectedItems(selectAllItems);

    // if (e.shiftKey && lastClickedIndex !== null) {
    //   const start = Math.min(lastClickedIndex, index);
    //   const end = Math.max(lastClickedIndex, index);

    //   updateBulkSelection(
    //     start,
    //     end,
    //     status,
    //     (e.target as HTMLInputElement).checked
    //   );

    //   // Prevent default to avoid checkbox state change
    //   e.preventDefault();
    // } else {
    //   setLastClickedIndex(index);
    // }
  };

  const handlePreviewClick = (url: string) => {
    setIsPreviewOpen(true);

    setPreviewUrl(url);
  };

  const host = previewUrl ? new URL(previewUrl).hostname : "";

  return (
    <>
      <Tabs initialTab={"0"} className={styles.tabsContainer}>
        <TabList className={styles.tabsList}>
          <TabListItem
            key={"0"}
            id={"0"}
            title={`Unreviewed (${unReviewedItems.length})`}
            className={styles.tabItem}
          />
          <TabListItem
            key={"1"}
            id={"1"}
            title={`Approved (${approvedItems.length})`}
            variant="positive"
            className={cn(styles.tabItem, styles.approvedTab)}
          />

          <TabListItem
            key={"2"}
            id={"2"}
            title={`Rejected (${rejectedItems.length})`}
            variant="negative"
            className={cn(styles.tabItem, styles.rejectedTab)}
          />
        </TabList>

        {isUsersItemsLoading ? (
          <div className={styles.loadingState}>
            <CircularProgress />
            Loading user's data
          </div>
        ) : (
          <div
            style={{
              width: "100%",
            }}
          >
            <Tab id="0">
              {unReviewedItems.length === 0 ? (
                <div className={styles.emptyState}>
                  All done! 👌
                  <p>No more items to review</p>
                </div>
              ) : (
                <UnReviewedItemsTab
                  unReviewedItems={unReviewedItems}
                  handlePreviewClick={handlePreviewClick}
                  handleUnReviewedSelectAllCheckBoxClick={
                    handleUnReviewedSelectAllCheckBoxClick
                  }
                  handleCheckBoxClick={handleCheckBoxClick}
                  handleKeyDown={handleKeyDown}
                  handleAcceptanceClick={handleAcceptanceClick}
                  handleRejectionClick={handleRejectionClick}
                />
              )}
            </Tab>

            <Tab id="1">
              {approvedItems.length === 0 ? (
                <div className={styles.emptyState}>
                  All clear! ✅<p>No approved items</p>
                </div>
              ) : (
                <ApprovedItemsTab
                  approvedItems={approvedItems}
                  handlePreviewClick={handlePreviewClick}
                  handleApprovedSelectAllCheckBoxClick={
                    handleApprovedSelectAllCheckBoxClick
                  }
                  handleCheckBoxClick={handleCheckBoxClick}
                  handleKeyDown={handleKeyDown}
                  handleAcceptanceClick={handleAcceptanceClick}
                  handleRejectionClick={handleRejectionClick}
                />
              )}
            </Tab>

            <Tab id="2">
              {rejectedItems.length === 0 ? (
                <div className={styles.emptyState}>
                  All clear! ✅<p>No Rejected items</p>
                </div>
              ) : (
                <RejectedItemsTab
                  rejectedItems={rejectedItems}
                  handlePreviewClick={handlePreviewClick}
                  handleRejectedSelectAllCheckBoxClick={
                    handleRejectedSelectAllCheckBoxClick
                  }
                  handleCheckBoxClick={handleCheckBoxClick}
                  handleKeyDown={handleKeyDown}
                  handleAcceptanceClick={handleAcceptanceClick}
                  handleRejectionClick={handleRejectionClick}
                />
              )}
            </Tab>
          </div>
        )}
      </Tabs>

      <Modal
        isOpen={isPreviewOpen}
        onClose={() => setIsPreviewOpen(false)}
        className={styles.previewModal}
      >
        <div className={styles.previewImageWrapper}>
          <p className={styles.previewImageUrl}>{host}</p>
          <img className={styles.previewImage} src={previewUrl} />
        </div>
      </Modal>
    </>
  );
};

export default UserItemsPageContent;
