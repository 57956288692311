import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useMemo,
  useEffect,
} from "react";
import cn from "classnames";
import styles from "./Tabs.module.scss";

const TabsContext = createContext<TabsContextProps>({
  activeTab: "",
  setActiveTab: () => {},
});

interface TabProps {
  id: string;
  children: ReactNode;
  className?: string;
}

const Tab = ({ id, children, className }: TabProps) => {
  const { activeTab } = useContext(TabsContext);

  return activeTab === id ? <div className={className}>{children}</div> : null;
};

interface TabListProps {
  children: ReactNode;
  className?: string;
}

const TabList = ({ children, className }: TabListProps) => {
  const { activeTab, setActiveTab } = useContext(TabsContext);
  return (
    <div className={cn(styles.wrapper, className)}>
      {React.Children.map(
        children,
        (
          child: any // eslint-disable-line @typescript-eslint/no-explicit-any
        ) =>
          React.cloneElement(child, {
            isActive: child.props.id === activeTab,
            onClick: () => setActiveTab(child.props.id),
          })
      )}
    </div>
  );
};

interface TabListItemProps {
  className?: string;
  id: string;
  title: string;
  isActive?: boolean;
  onClick?: () => void;
  variant?: "positive" | "negative" | "neutral";
}

const TabListItem = ({
  className,
  id,
  title,
  isActive,
  onClick,
  variant = "neutral",
}: TabListItemProps) => (
  <button
    key={id}
    type="button"
    className={cn(
      styles.tabListItem,
      { [styles.active]: isActive },
      styles[variant],
      className
    )}
    onClick={onClick}
  >
    {title}
  </button>
);

interface TabsContextProps {
  activeTab: string | undefined;
  setActiveTab: (id: string) => void;
}

export interface TabsProps {
  children: ReactNode;
  initialTab?: string;
  className?: string;
}

const Tabs = ({ children, initialTab, className }: TabsProps) => {
  // Use the URL hash to determine the active tab, defaulting to the initialTab prop
  const getActiveTab = () => {
    if (typeof window !== "undefined") {
      const hash = window.location.hash.replace("#", "");
      return hash || initialTab;
    }
    return initialTab;
  };

  const [activeTab, setActiveTab] = useState(getActiveTab);

  const handleTabChange = (id: string) => {
    // Update the URL hash when a new tab is selected
    const newUrl = `${window.location.pathname}#${id}`;
    window.history.pushState(null, "", newUrl);
    setActiveTab(id);
  };

  // Listen for changes in the hash part of the URL
  useEffect(() => {
    const handleHashChange = () => {
      setActiveTab(getActiveTab());
    };

    window.addEventListener("hashchange", handleHashChange, false);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("hashchange", handleHashChange, false);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const TabsContextProvider = useMemo(
    () => ({
      activeTab,
      setActiveTab: handleTabChange,
    }),
    [activeTab]
  );

  return (
    <TabsContext.Provider value={TabsContextProvider}>
      <div className={cn(styles.container, className)}>{children}</div>
    </TabsContext.Provider>
  );
};

export { Tabs, Tab, TabList, TabListItem };
