import React from "react";
import { Table } from "@spawning-inc/kiplu";
import { Checkbox, TableSortLabel } from "@mui/material";

import { Books } from "../../../types";

import styles from "./Books3Table.module.scss";
import Lozenge from "../../../components/Lozenge/Lozenge";

interface Books3TableProps {
  books: Books;
  orderValue: "is_reviewed" | "is_rejected" | "id";
  sortOrderDir: "asc" | "desc";
  numSelected: number;
  setBooks: React.Dispatch<React.SetStateAction<Books>>;
  handleSortRequest: (
    name: "is_reviewed" | "is_rejected" | "id"
  ) => void;
}

const Books3Table: React.FC<Books3TableProps> = ({
  books,
  orderValue,
  sortOrderDir,
  numSelected,
  setBooks,
  handleSortRequest,
}) => {
  const handleSortClick =
    (name: "is_reviewed" | "is_rejected" | "id") => () => {
      handleSortRequest(name);
    };

  const handleOnSelectAllClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;

    const selectAllBooks = books.map((book) => ({
      ...book,
      is_checked: checked,
    }));

    setBooks(selectAllBooks);
  };

  const handleOnSelectAllKeyDown = (
    e: React.KeyboardEvent<HTMLButtonElement>
  ) => {
    if (e.key === "Enter") {
      const selectAllBooks = books.map((book) => ({
        ...book,
        is_checked: true,
      }));

      setBooks(selectAllBooks);
    }

    if (e.key === "Backspace" || e.key === "Delete") {
      const selectAllBooks = books.map((book) => ({
        ...book,
        is_checked: false,
      }));

      setBooks(selectAllBooks);
    }
  };

  const handleBookRowClick = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    id: number,
    is_checked: boolean
  ) => {
    (e.target as HTMLInputElement).checked = !is_checked;

    const { checked } = e.target as HTMLInputElement;

    const newSelectionArray = books.map((book) =>
      book.id === id ? { ...book, is_checked: checked } : book
    );

    setBooks(newSelectionArray);
  };

  const handleKeyDown = (
    e:
      | React.KeyboardEvent<HTMLButtonElement>
      | React.KeyboardEvent<HTMLTableRowElement>,
    id: number,
    is_checked: boolean
  ) => {
    if (e.key === "Enter") {
      (e.target as HTMLInputElement).checked = !is_checked;

      const { checked } = e.target as HTMLInputElement;

      const newSelectionArray = books.map((book) =>
        book.id === id ? { ...book, is_checked: checked } : book
      );

      setBooks(newSelectionArray);
    }

    if (e.key === "Backspace" || e.key === "Delete") {
      (e.target as HTMLInputElement).checked = false;

      const { checked } = e.target as HTMLInputElement;

      const newSelectionArray = books.map((book) =>
        book.id === id ? { ...book, is_checked: checked } : book
      );

      setBooks(newSelectionArray);
    }
  };

  return (
    <Table>
      <Table.Head>
        <Table.Row>
          <Table.Cell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < books.length}
              checked={books.length > 0 && numSelected === books.length}
              onChange={(e) => handleOnSelectAllClick(e)}
              onKeyDown={(e) => handleOnSelectAllKeyDown(e)}
              inputProps={{
                "aria-label": "select all desserts",
              }}
            />
          </Table.Cell>
          <Table.Cell>
            <TableSortLabel
              className={styles.sortLable}
              active={orderValue === "id"}
              direction={orderValue === "id" ? sortOrderDir : "asc"}
              onClick={handleSortClick("id")}
            >
              Row ID
            </TableSortLabel>
          </Table.Cell>
          <Table.Cell>Title & Author</Table.Cell>
          <Table.Cell>User Email</Table.Cell>
          <Table.Cell>
            <TableSortLabel
              className={styles.sortLable}
              active={orderValue === "is_reviewed"}
              direction={orderValue === "is_reviewed" ? sortOrderDir : "asc"}
              onClick={handleSortClick("is_reviewed")}
            >
              Reviewed
            </TableSortLabel>
          </Table.Cell>
          <Table.Cell>
            <TableSortLabel
              className={styles.sortLable}
              active={orderValue === "is_rejected"}
              direction={orderValue === "is_rejected" ? sortOrderDir : "asc"}
              onClick={handleSortClick("is_rejected")}
            >
              Verified
            </TableSortLabel>
          </Table.Cell>
        </Table.Row>
      </Table.Head>

      <Table.Body>
        {books.map(
          ({
            id,
            email,
            entry,
            is_rejected,
            is_reviewed,
            is_checked,
          }) => (
            <Table.Row
              key={id}
              className={styles.bookRow}
              onClick={(e) => handleBookRowClick(e, id, is_checked || false)}
              onKeyDown={(e) => handleKeyDown(e, id, is_checked || false)}
            >
              <Table.Cell padding="checkbox">
                <Checkbox
                  color="primary"
                  checked={is_checked}
                  onChange={(e) =>
                    handleBookRowClick(e, id, is_checked || false)
                  }
                  onKeyDown={(e) => {
                    handleKeyDown(e, id, is_checked || false);
                  }}
                  inputProps={{
                    "aria-label": "select all desserts",
                  }}
                />
              </Table.Cell>
              <Table.Cell>{id}</Table.Cell>
              <Table.Cell>"{entry.title}" - {entry.authors ? entry.authors[0] : "null"}</Table.Cell>
              <Table.Cell>{email}</Table.Cell>
              <Table.Cell>
                <Lozenge
                  status={is_reviewed ? "positive" : "negative"}
                  type="reviewed"
                />
              </Table.Cell>
              <Table.Cell>
                <Lozenge
                  status={is_rejected ? "negative" : "positive"}
                  type="rejected"
                />
              </Table.Cell>
            </Table.Row>
          )
        )}
      </Table.Body>
    </Table>
  );
};

export default Books3Table;
