import React, { useState } from "react";
import cn from "classnames";
import styles from "./UsersDetails.module.scss";
import { Domains, User } from "../../../types";
import { ClickAwayListener, Popper } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";

interface UsersDetailsProps {
  user: User;
  domains: Domains | undefined;
}

const UsersDetails: React.FC<UsersDetailsProps> = ({ user, domains }) => {
  const { id, email } = user;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const domainStatusBadge = (isVerified: boolean, isReviewed: boolean) => {
    if (!isReviewed) {
      return (
        <div className={cn(styles.badge, styles.pending)}>
          <HourglassEmptyIcon fontSize="small" />
        </div>
      );
    }

    if (isReviewed && !isVerified) {
      return (
        <div className={cn(styles.badge, styles.failure)}>
          <CloseIcon fontSize="small" />
        </div>
      );
    }

    if (isReviewed && isVerified) {
      return (
        <div className={cn(styles.badge, styles.success)}>
          <CheckIcon fontSize="small" />
        </div>
      );
    }
  };

  return (
    <>
      <button
        className={styles.personalInfoButton}
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
          setIsOpen(!isOpen);
        }}
      >
        <div className={styles.personalInfo}>
          <div>{email}</div>
        </div>
      </button>

      <Popper
        anchorEl={anchorEl}
        open={isOpen}
        id={`popper-id-users-details-${id}`}
        placement="right-start"
      >
        <ClickAwayListener
          onClickAway={() => {
            setAnchorEl(null);
            setIsOpen(false);
          }}
        >
          <div className={styles.popperWrapper}>
            <div className={styles.personalInfoInPopper}>
              <div>{email}</div>
              <div>ID: {id}</div>
            </div>
            <div className={styles.domainsWrapper}>
              <div
                className={styles.domainsHeader}
              >{`Domains (${domains?.length})`}</div>
              {!domains ||
                (domains.length > 0 ? (
                  domains?.map(({ id, is_verified, is_reviewed, name }) => (
                    <div key={id} className={styles.domain}>
                      <div>{domainStatusBadge(is_verified, is_reviewed)}</div>
                      <div>{name}</div>
                    </div>
                  ))
                ) : (
                  <div>No domains</div>
                ))}
            </div>
          </div>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default UsersDetails;
