import { Box } from "@mui/material";
import React from "react";
import NavBar from "./NavBar/NavBar";

interface LayoutProps {
  children: React.ReactNode;
  height?: string;
}

const Layout: React.FC<LayoutProps> = ({ children, height }) => {
  return (
    <>
      <NavBar />
      <Box display="flex" padding="100px 40px">
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          position="absolute"
          right="40px"
          left="40px"
          padding="20px"
          top="108px"
          bottom="20px"
          height={height}
          sx={{
            backgroundColor: "#F2F2F2",
          }}
        >
          {children}
        </Box>
      </Box>
    </>
  );
};

export default Layout;
