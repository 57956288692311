import React, { useState, useEffect } from "react";
// import { LatLngTuple } from "react-leaflet";
import { Icon } from "leaflet";
import { Map, TileLayer, Polyline, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import Layout from "../../components/Layout";
import styles from "./KudurruPage.module.scss";

import { LatLng } from "leaflet";

type LatLngTuple = [number, number];

// Rest of the code remains the same

const clientIcon = new Icon({
  iconUrl: require("../../assets/marker-red.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

const serverIcon = new Icon({
  iconUrl: require("../../assets/marker-blue.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

interface MarkerData {
  data: any;
  ip: string;
  position: LatLngTuple;
  type: "client" | "server";
}

interface LineData {
  path: MarkerData[];
  target: MarkerData;
}

const fetchIpCoordinates = async (ip: string): Promise<LatLngTuple | null> => {
  const response = await fetch(`https://disneycarscase.info/ip_info?ip=${ip}`);
  const data = await response.json();
  if (!data.lat || !data.lon) {
    return null;
  }
  return [data.lat, data.lon] as LatLngTuple;
};

const fetchIpData = async (ip: string): Promise<any | null> => {
  const response = await fetch(`https://disneycarscase.info/ip_info?ip=${ip}`);
  const data = await response.json();
  if (!data.lat || !data.lon) {
    return null;
  }
  return data;
};

const KudurruPage: React.FC = () => {
  const [lines, setLines] = useState<LineData[]>([]);
  const [markers, setMarkers] = useState<MarkerData[]>([]);
  const [speed, setSpeed] = useState(0.05);
  const [maxLines, setMaxLines] = useState(80);
  const [ipInfo, setIpInfo] = useState<any[]>([]);
  const [ipCache, setIpCache] = useState<{ [key: string]: any | null }>({});
  const [currentPage, setCurrentPage] = useState(1);
  const [requestsPerMinute, setRequestsPerMinute] = useState(0);

  const [colorCache, setColorCache] = useState<{ [key: string]: string }>({});

  const PAGE_SIZE = 10;

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        "https://disneycarscase.info/recent_ip_and_domain"
      );
      const { client, server, per_minute } = await response.json();

      const clientCoords = await fetchIpCoordinates(client);
      const serverCoords = await fetchIpCoordinates(server);
      setRequestsPerMinute(per_minute);

      if (!clientCoords || !serverCoords) {
        return;
      }

      setLines((lines) => [
        ...lines.slice(lines.length >= maxLines ? 1 : 0),
        {
          path: [
            { data: null, ip: client, position: clientCoords, type: "client" },
          ],
          target: {
            data: null,
            ip: server,
            position: serverCoords,
            type: "server",
          },
        },
      ]);

      setMarkers((markers) => {
        const markerExists = (position: LatLngTuple) =>
          markers.some(
            (marker) =>
              marker.position[0] === position[0] &&
              marker.position[1] === position[1]
          );

        let newMarkers = [...markers];

        if (!markerExists(clientCoords)) {
          newMarkers.push({
            data: null,
            ip: client,
            position: clientCoords,
            type: "client",
          });
        }

        if (!markerExists(serverCoords)) {
          newMarkers.push({
            data: null,
            ip: "Kudurru",
            position: serverCoords,
            type: "server",
          });
        }

        return newMarkers;
      });

      if (!ipCache[client]) {
        const clientInfo = await fetchIpData(client);
        setIpCache((ipCache) => ({ ...ipCache, [client]: clientInfo }));
      }

      if (!colorCache[client]) {
        setColorCache((colorCache) => ({
          ...colorCache,
          [client]: getRandomColor(),
        }));
      }

      const clientInfo = ipCache[client];
      if (clientInfo) {
        const ipInfoData = [{ ip: client, ...clientInfo }];
        setIpInfo((ipInfo) => [{ ip: client, ...clientInfo }, ...ipInfo]);
      }
    };

    fetchData();
    const intervalId = setInterval(fetchData, 30000);

    return () => {
      clearInterval(intervalId);
    };
  }, [maxLines, ipCache]);

  useEffect(() => {
    const animateLines = () => {
      setLines((lines) =>
        lines.map((line) => {
          const lastPoint = line.path[line.path.length - 1];
          const nextPoint: MarkerData = {
            data: null,
            ip: line.path[0].ip,
            type: line.path[0].type,
            position: [
              lastPoint.position[0] +
                (line.target.position[0] - lastPoint.position[0]) * speed,
              lastPoint.position[1] +
                (line.target.position[1] - lastPoint.position[1]) * speed,
            ],
          };

          const midPoint: MarkerData = {
            data: null,
            ip: line.path[0].ip,
            type: line.path[0].type,
            position: [
              (lastPoint.position[0] + nextPoint.position[0]) / 2,
              (lastPoint.position[1] + nextPoint.position[1]) / 2,
            ],
          };

          return {
            ...line,
            path: [...line.path, midPoint, nextPoint],
          };
        })
      );
      requestAnimationFrame(animateLines);
    };

    animateLines();
  }, [speed]);

  useEffect(() => {
    setCurrentPage(1);
  }, [ipInfo]);

  const totalPages = Math.ceil(ipInfo.length / PAGE_SIZE);
  const startIndex = (currentPage - 1) * PAGE_SIZE;
  const endIndex = startIndex + PAGE_SIZE;
  const displayedIpInfo = ipInfo.slice(startIndex, endIndex);

  return (
    <Layout height="fit-content">
      <div className={styles.wrapper}>
        <p>{Math.round(requestsPerMinute)}/m</p>
        <a href="./kudurrudomains">Domains</a>
        <a href="./kudurruwords">Words</a>
        <label style={{ display: "none" }}>
          Animation Speed:
          <input
            type="number"
            min="0"
            step="0.01"
            value={speed}
            onChange={(e) => setSpeed(parseFloat(e.target.value))}
          />
        </label>
        <label style={{ display: "none" }}>
          Max Lines:
          <input
            type="number"
            min="1"
            value={maxLines}
            onChange={(e) => setMaxLines(parseInt(e.target.value))}
          />
        </label>
        <div
          className={styles.wrapper}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Map
            center={[0, 0]}
            zoom={2.1}
            style={{ height: "500px", width: "1000px" }}
            dragging={false} // Prevent panning
            zoomControl={false} // Disable zoom control
            scrollWheelZoom={false} // Prevent zooming with mouse scroll
            doubleClickZoom={false} // Prevent zooming on double click
            touchZoom={false} // Prevent touch zoom
            worldCopyJump={false} // Prevent from showing continuous worlds horizontally
            maxBounds={[
              [-90, -180],
              [90, 180],
            ]} // Set bounds of the map
            minZoom={2} // Set minimum zoom level to avoid seeing the map repeating
          >
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            {lines.map((line, i) => (
              <Polyline
                positions={line.path.map((point) => point.position)}
                color={colorCache[line.path[0].ip]}
                key={i}
              />
            ))}
            {markers.map((marker, i) => (
              <Marker
                position={marker.position}
                key={i}
                icon={marker.type === "client" ? clientIcon : serverIcon}
              >
                <Popup>
                  {" "}
                  <a
                    href={`kudurru/${marker.ip}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {marker.ip}
                  </a>
                </Popup>
              </Marker>
            ))}
          </Map>
        </div>

        <div>
          <h2>IP Information</h2>
          <table>
            <thead>
              <tr>
                <th>IP Address</th>
                <th>City</th>
                <th>Region</th>
                <th>Country</th>
                <th>ISP</th>
                <th>Organization</th>
                <th>Zip Code</th>
                <th>Latitude</th>
                <th>Longitude</th>
                <th>Timezone</th>
                <th>AS</th>
              </tr>
            </thead>
            <tbody>
              {displayedIpInfo.map((info, i) => (
                <tr key={i}>
                  <td>
                    <a
                      href={`kudurru/${info.ip}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {info.ip}
                    </a>
                  </td>
                  <td>{info.city}</td>
                  <td>{info.regionName || info.region}</td>
                  <td>{info.country}</td>
                  <td>{info.isp}</td>
                  <td>{info.org}</td>
                  <td>{info.zip}</td>
                  <td>{info.lat}</td>
                  <td>{info.lon}</td>
                  <td>{info.timezone}</td>
                  <td>{info.as}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div>
            <button
              disabled={currentPage === 1}
              onClick={() => setCurrentPage(currentPage - 1)}
            >
              Previous
            </button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <button
              disabled={currentPage === totalPages}
              onClick={() => setCurrentPage(currentPage + 1)}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default KudurruPage;
