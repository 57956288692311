import React from "react";

import styles from "./ControlsBar.module.scss";
import { Button } from "@spawning-inc/kiplu";

interface ControlsBarProps {
  message: string;
  numberOfSelectedItems: number;
  onApprove: () => void;
  onReject: () => void;
  handleUnReviewedSelectAllCheckBoxClick?: (
    e: React.ChangeEvent<HTMLInputElement>
  ) => void;
  handleApprovedSelectAllCheckBoxClick?: (
    e: React.ChangeEvent<HTMLInputElement>
  ) => void;
  handleRejectedSelectAllCheckBoxClick?: (
    e: React.ChangeEvent<HTMLInputElement>
  ) => void;
}

const ControlsBar: React.FC<ControlsBarProps> = ({
  message,
  numberOfSelectedItems,
  handleUnReviewedSelectAllCheckBoxClick,
  handleApprovedSelectAllCheckBoxClick,
  handleRejectedSelectAllCheckBoxClick,
  onApprove,
  onReject,
}) => {
  return (
    <div className={styles.infoBarWrapper}>
      {handleUnReviewedSelectAllCheckBoxClick && (
        <label className={styles.selectAllCheckBoxWrapper}>
          <input
            onChange={(e) => handleUnReviewedSelectAllCheckBoxClick(e)}
            className={styles.selectAllCheckBox}
            type="checkbox"
          />
          Select All
        </label>
      )}

      {handleApprovedSelectAllCheckBoxClick && (
        <label className={styles.selectAllCheckBoxWrapper}>
          <input
            onChange={(e) => handleApprovedSelectAllCheckBoxClick(e)}
            className={styles.selectAllCheckBox}
            type="checkbox"
          />
          Select All
        </label>
      )}

      {handleRejectedSelectAllCheckBoxClick && (
        <label className={styles.selectAllCheckBoxWrapper}>
          <input
            onChange={(e) => handleRejectedSelectAllCheckBoxClick(e)}
            className={styles.selectAllCheckBox}
            type="checkbox"
          />
          Select All
        </label>
      )}
      <div className={styles.message}>{message}</div>

      <div className={styles.buttonsWrapper}>
        <Button
          variant="secondary"
          onClick={onApprove}
          disabled={numberOfSelectedItems === 0}
          className={styles.approveButton}
        >
          Approve {numberOfSelectedItems} items
        </Button>
        <Button
          variant="secondary"
          onClick={onReject}
          disabled={numberOfSelectedItems === 0}
          className={styles.rejectButton}
        >
          Reject {numberOfSelectedItems} items
        </Button>
      </div>
    </div>
  );
};

export default ControlsBar;
