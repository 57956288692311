import React, { useEffect, useState } from "react";
import axios from "axios";
import { Chart } from "chart.js";
import * as d3 from "d3";
import Layout from "../../components/Layout";

interface DataItem {
  Word: string;
  En_Frequency: number;
  Count: number;
  Frequency_Rank?: number; // Add these two new properties
  Word_Count?: number;
}

const getData = async (): Promise<DataItem[]> => {
  try {
    const response = await axios.get("https://disneycarscase.info/word_freq", {
      withCredentials: false,
    });

    // The response data is already a JSON array
    let parsedData: DataItem[] = response.data;

    // Sorting and ranking
    parsedData.sort((a, b) => b.En_Frequency - a.En_Frequency);
    parsedData.forEach((data, index) => {
      data.Frequency_Rank = index + 1;
    });

    parsedData.sort((a, b) => b.Count - a.Count);
    parsedData.forEach((data, index) => {
      data.Word_Count = index + 1;
    });

    return parsedData;
  } catch (error) {
    console.error(`Failed to fetch data: ${error}`);
    return []; // Return an empty array in case of error
  }
};

const createChart = (data: DataItem[]) => {
  const ctx = document.getElementById("myChart") as HTMLCanvasElement;

  const colorScale = d3
    .scaleLog<string>()
    .domain([
      Math.max(
        1e-6,
        d3.min(data, (d: DataItem) => d.En_Frequency + 1e-6) || 1e-6
      ),
      d3.max(data, (d: DataItem) => d.En_Frequency + 1e-6) || 1e-6,
    ])
    .range(["red", "blue"])
    .interpolate(d3.interpolateRgb);

  new Chart(ctx, {
    type: "bar",
    data: {
      labels: data.map((d: DataItem) => d.Word),
      datasets: [
        {
          label: "Word Count",
          data: data.map((d: DataItem) => d.Count),
          backgroundColor: data.map((d: DataItem) =>
            colorScale(d.En_Frequency)
          ),
          borderColor: data.map((d: DataItem) => colorScale(d.En_Frequency)),
          borderWidth: 1,
        },
      ],
    },
    options: {
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    },
  });
};

const KudurruWords: React.FC = () => {
  const [data, setData] = useState<DataItem[]>([]);

  useEffect(() => {
    getData().then((fetchedData) => {
      setData(fetchedData);
      createChart(fetchedData);
    });
  }, []);

  return (
    <Layout>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <canvas id="myChart" width="1200" height="800"></canvas>
        <table style={{ margin: "auto", textAlign: "center" }}>
          <thead>
            <tr>
              <th>Word</th>
              <th>English Frequency</th>
              <th>Frequency Rank</th> {/* Add these two new columns */}
              <th>Count</th>
              <th>Count Rank</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td>{item.Word}</td>
                <td>{item.En_Frequency}</td>
                <td>{item.Frequency_Rank}</td>{" "}
                {/* Add these two new data rows */}
                <td>{item.Count}</td>
                <td>{item.Word_Count}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Layout>
  );
};

export default KudurruWords;
