import { Book, Books } from "../types";

const descendingComparator = (a: Book, b: Book, orderBy: keyof Book) => {
  if (b[orderBy]! < a[orderBy]!) {
    return -1;
  }
  if (b[orderBy]! > a[orderBy]!) {
    return 1;
  }
  return 0;
};

export const getBookComparator = (
  order: "asc" | "desc",
  orderBy: keyof Book
) => {
  return order === "desc"
    ? (a: Book, b: Book) => descendingComparator(a, b, orderBy)
    : (a: Book, b: Book) => -descendingComparator(a, b, orderBy);
};

export const sortedBooksInformation = (
  rowArr: Books,
  comparator: any
): Books => {
  const stabalizedRowArr = rowArr.map((el, index) => [el, index]);

  stabalizedRowArr.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return Number(a[1]) - Number(b[1]);
  });

  return stabalizedRowArr.map((el) => el[0]) as Books;
};
