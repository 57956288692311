import React from "react";

import ControlsBar from "../../../ControlsBar/ControlsBar";
import { Selections } from "../../../../../types";
import IndividualUserSelection from "../../../../IndividualUserPage/IndividualUserSelection/IndividualUserSelection";

import styles from "./UnReviewedItemsTab.module.scss";

interface UnReviewedItemsTabProps {
  unReviewedItems: Selections;
  handlePreviewClick: (url: string) => void;
  handleUnReviewedSelectAllCheckBoxClick: (
    e: React.ChangeEvent<HTMLInputElement>
  ) => void;
  handleCheckBoxClick: (
    e: React.ChangeEvent<HTMLInputElement>,
    id: number,
    index: number,
    status: "approved" | "rejected" | "unReviewed"
  ) => void;
  handleKeyDown: (
    e: React.KeyboardEvent<HTMLInputElement>,
    id: number,
    status: "approved" | "rejected" | "unReviewed"
  ) => void;
  handleAcceptanceClick: (
    type: "unReviewed" | "approved" | "rejected"
  ) => Promise<void>;
  handleRejectionClick: (
    type: "unReviewed" | "approved" | "rejected"
  ) => Promise<void>;
}

const UnReviewedItemsTab: React.FC<UnReviewedItemsTabProps> = ({
  unReviewedItems,
  handlePreviewClick,
  handleUnReviewedSelectAllCheckBoxClick,
  handleCheckBoxClick,
  handleKeyDown,
  handleAcceptanceClick,
  handleRejectionClick,
}) => {
  return (
    <div className={styles.tabContentWrapper}>
      <h1 className={styles.tabHeader}>Unreviewed Items</h1>

      <ControlsBar
        message={`${unReviewedItems.length} items need review`}
        numberOfSelectedItems={
          unReviewedItems.filter(({ is_checked }) => is_checked).length
        }
        handleUnReviewedSelectAllCheckBoxClick={
          handleUnReviewedSelectAllCheckBoxClick
        }
        onApprove={() => handleAcceptanceClick("unReviewed")}
        onReject={() => handleRejectionClick("unReviewed")}
      />
      <div className={styles.imagesHolder}>
        {unReviewedItems.map((item, idx) => (
          <div key={item.id} className={styles.imageWrapper}>
            <IndividualUserSelection
              index={idx}
              currentUserSelection={item}
              handlePreviewClick={handlePreviewClick}
              handleCheckBoxClick={handleCheckBoxClick}
              handleKeyDown={handleKeyDown}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default UnReviewedItemsTab;
