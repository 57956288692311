import { Alert } from "@mui/material";
import React from "react";
interface ErrorAlertProps {
  isError: boolean;
  setIsError: React.Dispatch<React.SetStateAction<boolean>>;
  errorMessage: string;
}

const ErrorAlert: React.FC<ErrorAlertProps> = ({
  isError,
  setIsError,
  errorMessage,
}) => {
  return (
    <>
      {isError ? (
        <Alert
          variant="filled"
          severity="error"
          onClose={() => setIsError(false)}
          sx={{ width: "300px" }}
        >
          {errorMessage}
        </Alert>
      ) : (
        ""
      )}
    </>
  );
};

export default ErrorAlert;
