// Import React and necessary components
import React from "react";
import Layout from "../../components/Layout";
import DomainActivityTable from "../../components/DomainActivityTable/DomainActivityTable";

// Create a functional component
const KudurruDomains: React.FC = () => {
  return (
    <Layout>
      <DomainActivityTable />
    </Layout>
  );
};

// Export the component
export default KudurruDomains;
