import React, { useEffect, useState } from "react";
import styles from "./Books3Page.module.scss";
import Layout from "../../components/Layout";
import { Select, TextField } from "@spawning-inc/kiplu";
import { Books } from "../../types";
import axios from "axios";
import { END_POINT } from "../../consts";
import Books3Table from "./Books3Table/Books3Table";
import { Button, CircularProgress, Pagination } from "@mui/material";
import {
  getBookComparator,
  sortedBooksInformation,
} from "../../helpers/booksSorting";
import ErrorAlert from "../../components/ErrorAlert";

const itemsPerPageOptions = [
  { value: 2, name: "2" },
  { value: 5, name: "5" },
  { value: 20, name: "20" },
  { value: 50, name: "50" },
  { value: 100, name: "100" },
  { value: 500, name: "500" },
  { value: 1000, name: "1000" },
];

const Books3Page = () => {
  const [currentBooks, setCurrentBooks] = useState<Books>([]);
  const [totalBooks, setTotalBooks] = useState<Books>([]);
  const [numSelected, setNumSelected] = useState(0);
  const [areAllBooksReviewed, setAreAllBooksReviewed] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [sortOrderDir, setSortOrderDir] = useState<"asc" | "desc">("asc");
  const [orderValue, setOrderValue] = useState<
    "is_reviewed" | "is_rejected" | "id"
  >("is_reviewed");

  const [itemsPerPage, setItemsPerPage] = useState(
    itemsPerPageOptions[2].value
  );
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState({
    count: 0,
    from: 0,
    to: itemsPerPage,
  });

  const getAllBooks = async () => {
    try {
      const data = await axios.get(`${END_POINT}/books3?limit=20000`);

      const { data: books } = data;

      // added a 'is_checked' property to each book
      const transformedSelections = books.map((selection: Selection) => ({
        ...selection,
        is_checked: false,
      }));

      await setTotalBooks(transformedSelections);
      setPagination({ ...pagination, count: books.length });

      setIsError(false);
    } catch (error) {
      setIsError(true);
      setErrorMessage("Failed to get list of books");
    }
  };

  const getBooks = async (page: number, num: number) => {
    setIsLoading(true);
    try {
      const data = await axios.get(
        `${END_POINT}/books3?offset=${page}&limit=${num}`
      );

      const { data: books } = data;

      // added a 'is_checked' property to each books
      const transformedSelections = books.map((selection: Selection) => ({
        ...selection,
        is_checked: false,
      }));

      await setCurrentBooks(transformedSelections);
      setIsLoading(false);
      setIsError(false);
    } catch (error) {
      setIsLoading(false);

      setIsError(true);
      setErrorMessage("Failed to get list of books");
    }
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    event.preventDefault();
    const from = (page - 1) * itemsPerPage;
    const to = itemsPerPage;

    setPage(page);
    setPagination({ ...pagination, from, to });
  };

  const handleSortRequest = (
    name: "is_reviewed" | "is_rejected" | "id"
  ) => {
    const isAsc = orderValue === name && sortOrderDir === "asc";
    setOrderValue(name);
    setSortOrderDir(isAsc ? "desc" : "asc");
  };

  const handleItemsPerPageSelectChange = (
    target:
      | (EventTarget & HTMLInputElement)
      | (EventTarget & { value: unknown; name: string })
  ) => {
    setItemsPerPage(Number(target.value));
  };

  const updateBook = async () => {
    try {
      const booksToBeUpdated = currentBooks.filter(
        ({ is_checked }) => is_checked === true
      );

      await axios.put(`${END_POINT}/books3/`, {
        books: booksToBeUpdated.map((book) => ({
          id: book.id,
          is_reviewed: book.is_reviewed
        })),
      });

      await getBooks(pagination.from, pagination.to);

      setIsError(false);
    } catch (error) {
      setIsError(true);
      setErrorMessage("Failed to update book settings.");
    }
  };

  const toggleApproveBook = async (status: boolean) => {
    try {
      const booksToBeUpdated = currentBooks.filter(
        ({ is_checked }) => is_checked === true
      );

      await axios.put(`${END_POINT}/books3/`, {
        books: booksToBeUpdated.map((book) => ({
          id: book.id,
          is_rejected: status
        })),
      });

      await getBooks(pagination.from, pagination.to);

      setIsError(false);
    } catch (error) {
      setIsError(true);
      setErrorMessage("Failed to verify books.");
    }
  };

  const toggleProcessBook = async (status: boolean) => {
    try {
      const booksToBeUpdated = currentBooks.filter(
        ({ is_checked }) => is_checked === true
      );

      await axios.put(`${END_POINT}/books3/`, {
        books: booksToBeUpdated.map((book) => ({
          id: book.id,
          is_rejected: book.is_rejected,
          is_processed: status,
        })),
      });

      await getBooks(pagination.from, pagination.to);

      setIsError(false);
    } catch (error) {
      setIsError(true);
      setErrorMessage("Failed to update book settings.");
    }
  };

  useEffect(() => {
    getAllBooks();
  }, []);

  useEffect(() => {
    getBooks(pagination.from, pagination.to);

    setPagination({ ...pagination, to: itemsPerPage });
  }, [pagination.from, pagination.to, itemsPerPage]);

  useEffect(() => {
    const booksChecked = currentBooks.filter(
      ({ is_checked }) => !!is_checked
    );

    const notReviewedBooks = currentBooks.filter(
      ({ is_reviewed }) => !is_reviewed
    );

    setAreAllBooksReviewed(notReviewedBooks.length === 0);
    setNumSelected(booksChecked.length);
  }, [currentBooks]);

  const sortedBooks = sortedBooksInformation(
    currentBooks,
    getBookComparator(sortOrderDir, orderValue)
  );

  const handleSearch = () => {
    const filteredsBooks = totalBooks.filter(({ entry, email }) => {
      return (
        entry.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        email.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });

    setCurrentBooks(filteredsBooks);
    setPagination({ ...pagination, count: currentBooks.length });
  };

  const handleEnterSearch = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <Layout height="fit-content">
      <div className={styles.wrapper}>
        <h1>Books</h1>

        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            {`${sortedBooks.length} out of ${totalBooks.length}`}

            <div className={styles.tableWrapper}>
              <div className={styles.tableControlsWrapper}>
                <TextField
                  className={styles.searchField}
                  placeholder="Search by email"
                  onChange={(e) => setSearchTerm(e.target.value)}
                  onKeyDown={(e) => handleEnterSearch(e)}
                  value={searchTerm}
                  InputProps={{
                    endAdornment: (
                      <button
                        className={styles.searchButton}
                        onClick={handleSearch}
                      >
                        search
                      </button>
                    ),
                    startAdornment: searchTerm !== "" && (
                      <button
                        className={styles.clearButton}
                        onClick={() => {
                          setSearchTerm("");
                          getBooks(pagination.from, pagination.to);
                        }}
                      >
                        clear
                      </button>
                    ),
                  }}
                />

                {numSelected > 0 && (
                  <div className={styles.actionButtonsWrapper}>
                    <Button
                      disabled={numSelected <= 0 || areAllBooksReviewed}
                      className={styles.reviewButton}
                      onClick={updateBook}
                    >
                      {areAllBooksReviewed
                        ? "All books are reviewed"
                        : `Mark ${
                            numSelected > 0 ? numSelected : ""
                          } as Reviewed`}
                    </Button>

                    <div className={styles.dividerLine} />

                    <Button
                      disabled={numSelected <= 0}
                      className={styles.verifyButton}
                      onClick={() => toggleApproveBook(false)}
                    >
                      Approve
                    </Button>

                    <div className={styles.dividerLine} />

                    <Button
                      disabled={numSelected <= 0}
                      className={styles.verifyButton}
                      onClick={() => toggleApproveBook(true)}
                    >
                      Reject
                    </Button>

                  </div>
                )}

                <div className={styles.itemsPerPageSelect}>
                  <span>Items per page</span>

                  <Select
                    defaultValue={itemsPerPage}
                    options={itemsPerPageOptions}
                    onChange={({ target }) => {
                      handleItemsPerPageSelectChange(target);
                    }}
                  />
                </div>
              </div>

              {sortedBooks.length > 0 ? (
                <>
                  <Books3Table
                    books={sortedBooks}
                    orderValue={orderValue}
                    sortOrderDir={sortOrderDir}
                    numSelected={numSelected}
                    setBooks={setCurrentBooks}
                    handleSortRequest={handleSortRequest}
                  />
                  <div className={styles.paginationHolder}>
                    <Pagination
                      page={page}
                      count={Math.ceil(pagination.count / itemsPerPage)}
                      onChange={handlePageChange}
                    />
                  </div>
                </>
              ) : (
                <p>No books found</p>
              )}
            </div>
          </>
        )}

        <ErrorAlert
          isError={isError}
          setIsError={setIsError}
          errorMessage={errorMessage}
        />
      </div>
    </Layout>
  );
};

export default Books3Page;
