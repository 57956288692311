import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import styles from "./AudioPlayer.module.scss";

interface AudioPlayerProps {
  url: string;
  handleOnError?: () => void;
  className?: string;
}

const AudioPlayer = ({ url, handleOnError, className }: AudioPlayerProps) => {
  const audioPlayer = useRef<any>(); // eslint-disable-line @typescript-eslint/no-explicit-any
  const progressBar = useRef<any>(); // eslint-disable-line @typescript-eslint/no-explicit-any
  const animationRef = useRef<any>(); // eslint-disable-line @typescript-eslint/no-explicit-any

  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  const whilePlaying = () => {
    progressBar.current.value = audioPlayer.current.currentTime;
    setCurrentTime(progressBar.current.value);
    animationRef.current = requestAnimationFrame(whilePlaying);
  };

  useEffect(() => {
    const seconds = Math.floor(audioPlayer.current?.duration);
    setDuration(seconds);

    progressBar.current.max = seconds;

    if (isPlaying) {
      audioPlayer.current.play();
      animationRef.current = requestAnimationFrame(whilePlaying);
      return;
    }

    audioPlayer.current.pause();
    cancelAnimationFrame(animationRef.current);
  }, [isPlaying, progressBar, audioPlayer, audioPlayer.current?.duration]); // eslint-disable-line react-hooks/exhaustive-deps

  const changeRange = () => {
    audioPlayer.current.currentTime = progressBar.current.value;
    setCurrentTime(progressBar.current.value);
  };

  const calculateTime = (secs: number) => {
    const minutes = Math.floor(secs / 60);
    const returnedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const seconds = Math.floor(secs % 60);
    const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${returnedMinutes}:${returnedSeconds}`;
  };

  return (
    <div className={styles.audioPlayer}>
      <audio ref={audioPlayer} src={url} onError={handleOnError}>
        <track kind="captions" />
      </audio>
      <div className={styles.audioPlayerWrapper}>
        {!Number.isNaN(duration) && (
          <div className={styles.timeWrapper}>
            <div>
              {calculateTime(currentTime)}
              {`/${calculateTime(duration)}`}
            </div>
          </div>
        )}
        <input
          ref={progressBar}
          type="range"
          className={cn(styles.progressBar, className)}
          defaultValue="0"
          onChange={changeRange}
        />

        <button
          type="button"
          onClick={() => setIsPlaying(!isPlaying)}
          className={styles.playPauseButton}
        >
          {isPlaying ? (
            <PauseCircleIcon className={styles.pauseButton} />
          ) : (
            <PlayCircleOutlineIcon className={styles.playButton} />
          )}
        </button>
      </div>
    </div>
  );
};

export default AudioPlayer;
