import React from "react";
import { Button, Modal } from "@spawning-inc/kiplu";
import styles from "./NotesModal.module.scss";
import Textarea from "@mui/material/TextareaAutosize";
import { useState } from "react";

interface NotesModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  notes: string;
  onSave: (newNotesText: string) => void;
}

const NotesModal: React.FC<NotesModalProps> = ({
  isOpen,
  setIsOpen,
  notes,
  onSave,
}) => {
  const [notesText, setNotesText] = useState(notes);

  return (
    <Modal
      isOpen={isOpen}
      footer={
        <div className={styles.buttonsWrapper}>
          <Button onClick={() => setIsOpen(false)}>Cancel</Button>
          <Button onClick={() => onSave(notesText)}>Save</Button>
        </div>
      }
    >
      <Textarea
        className={styles.textarea}
        placeholder="Notes"
        defaultValue={notes}
        onChange={(e) => setNotesText(e.target.value)}
      />
    </Modal>
  );
};

export default NotesModal;
